import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import "./Settings.css";
import Cookies from "js-cookie";
import axios from "axios";
import { useState } from "react";

const Settings = (props) => {
  const user = useSelector((state) => state.user);

  const token = Cookies.get("token");

  const [loading, setLoading] = useState(false);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    showCloseButton: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (e.target.new_pass.value !== e.target.repeat_pass.value) {
      Toast.fire({
        icon: "error",
        title: '"Новый пароль" и "Повторите новый пароль" не совпадают',
      });
    } else if (e.target.new_pass.value === e.target.current_pass.value) {
      Toast.fire({
        icon: "error",
        title: '"Текущий пароль" и "Новый пароль" совпадают',
      });
    } else {
      axios.defaults.headers.put["Authorization"] = `jwt ${token}`;
      try {
        setLoading(true);
        await axios.put("https://api.pavelandco.com/api/user/password", {
          old: e.target.current_pass.value,
          new: e.target.new_pass.value,
        });
        Toast.fire({
          icon: "success",
          title: "Пароль успешно изменен",
        });
        e.target.current_pass.value = "";
        e.target.new_pass.value = "";
        e.target.repeat_pass.value = "";
      } catch (error) {
        if (error.response.status === 409) {
          Toast.fire({
            icon: "error",
            title: "Неверный пароль",
          });
        } else {
          Toast.fire({
            icon: "error",
            title: "Ошибка",
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Container className="user-settings-container">
        <Row className="user-settings-email-row">
          <Col md={1} xs={2}>
            <div className="user-settings-icon">
              {user.email && user.email.slice(0, 1).toUpperCase()}
            </div>
          </Col>
          <Col md={11} xs={10} className="user-settings-email">
            <span>{user.email}</span>
            <span>Привязанный Email</span>
          </Col>
        </Row>
        <form onSubmit={handlePasswordChange}>
          <Row>
            <Col className="reset-pass-inputs">
              <input
                className="input-type-1"
                id="current_pass"
                name="current_pass"
                required
                placeholder="Текущий пароль"
              />
              <input
                className="input-type-1"
                id="new_pass"
                name="new_pass"
                required
                placeholder="Новый пароль"
              />
              <input
                className="input-type-1"
                id="repeat_pass"
                name="repeat_pass"
                required
                placeholder="Повторите новый пароль"
              />
            </Col>
          </Row>
          <Row>
            <Col className="settings-form-centring-col">
              <button
                type="submit"
                className={`pass-reset-button ${loading && "axios-loading"}`}
                disabled={loading}
              >
                СОХРАНИТЬ
              </button>
            </Col>
          </Row>
        </form>
        <Row>
          <Col className="settings-form-centring-col">
            <div className="hint-full">
              <div className="hint-icon">i</div>
              <span className="hint-text">
                Изменение Email возможно только при обращении в поддержку
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Settings;
