import { Accordion, Col, Container, Row } from "react-bootstrap";
import { MD5 } from "crypto-js";

const OrderFAQ = ({ FAQtext }) => {
  const half = Math.ceil(FAQtext.length / 2);
  return (
    <Container
      fluid
      className="service-content-container contact-banner-container order-faq"
    >
      <Row className="landing-faq-container">
        <Col className="landing-faq-container-title-col">
          <div className="landing-faq-container-title">Популярные вопросы</div>
          <div className="landing-faq-container-caption">
            Не нашли ответ на свой вопрос?
            <a href="javascript:jivo_api.open();">
              <button>Написать</button>
            </a>
          </div>
        </Col>
      </Row>
      <Row className="landing-faq-questions-container">
        <Col md={6}>
          <Accordion>
            {FAQtext.slice(0, half).map((text, index) => {
              return (
                <Accordion.Item eventKey={index} key={MD5(text)}>
                  <Accordion.Header>{text.q}</Accordion.Header>
                  <Accordion.Body>{text.a}</Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Col>
        <Col md={6}>
          <Accordion>
            {FAQtext.slice(half).map((text, index) => {
              return (
                <Accordion.Item eventKey={index} key={MD5(text)}>
                  <Accordion.Header>{text.q}</Accordion.Header>
                  <Accordion.Body>{text.a}</Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default OrderFAQ;
