import { Col, Row } from "react-bootstrap";

const TitleBanner = ({ orderText }) => {
  return (
    <Row className="home-page-start-row mb-5">
      <Col md={4} className=" hide-on-mobile hide-on-medium">
        <img
          src={`${process.env.PUBLIC_URL}/images/${orderText.banner_image}.svg`}
          alt="orderIcon"
          className="seo-title-banner"
        />
      </Col>
      <Col xs={12} md={8}>
        <h1 className="landing-title">{orderText.banner_title}</h1>
        <h2 className="landing-start-caption">{orderText.banner_caption}</h2>
        <img
          src={`${process.env.PUBLIC_URL}/images/${orderText.banner_image}.svg`}
          alt="orderIcon"
          className="landing-gif hide-on-desktop"
        />
      </Col>
    </Row>
  );
};

export default TitleBanner;
