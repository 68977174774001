import { Container, Row } from "react-bootstrap";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { text as orderTextImport } from "../../../../assets/texts/orderForm.js";
import SeoOrderForm from "../SeoOrderForm/SeoOrderForm";
import TitleBanner from "../SeoOrderForm/TitleBanner";

const Telegram = () => {
  let title;
  let orderType;
  const sampleLocation = useLocation();
  if (sampleLocation.pathname.includes("message")) {
    title = "Рассылку в личные сообщения";
    orderType = "tg_spam";
  } else if (sampleLocation.pathname.includes("inviting")) {
    title = "Инвайтинг";
    orderType = "tg_inviting";
  } else if (sampleLocation.pathname.includes("group")) {
    title = "Рассылку по чатам";
    orderType = "tg_groupSpam";
  } else if (sampleLocation.pathname.includes("bots")) {
    title = "Боты";
    orderType = "tg_bots";
  } else if (sampleLocation.pathname.includes("views")) {
    title = "Просмотры";
    orderType = "tg_views";
  }
  const orderText = orderTextImport[orderType];

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="message" replace={true} />} />
      </Routes>
      <Container>
        <Row className="you-chose-services-row">
          {orderText && <TitleBanner orderText={orderText} />}
          <Outlet />
        </Row>
      </Container>
    </>
  );
};

export default Telegram;
