import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Error.css";

const Error = () => {
  return (
    <>
      <Container className="error-page-container">
        <Row>
          <Col>
            <img src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="logo" />
          </Col>
        </Row>
        <Row>
          <Col className="error-page-title">Страница не найдена...</Col>
        </Row>
        <Row>
          <Col className="error-page-content">
            Может стоит начать <Link to="/"> сначала</Link>?
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Error;
