export const text = {
  tg_spam: {
    banner_title: "Рассылка сообщений",
    banner_caption:
      "Лучший способ продвижения вашего канала, личного аккаунта, бота или сайта",
    banner_image: "msg",
  },
  tg_inviting: {
    banner_title: "Инвайтинг",
    banner_caption:
      "Лучший способ продвижения вашего группового чата в Telegram",
    banner_image: "inviting",
  },
  tg_views: {
    banner_title: "Накрутка Просмотров",
    banner_caption:
      "Идеально подходит для придания аудитории большей вовлеченности, отлично комбинируется с другими услугами по продвижению канала для достижения лучших результатов",
    banner_image: "views",
  },
  tg_bots: {
    banner_title: "Накрутка Ботов",
    banner_caption:
      "Отличный способ увеличить количество подписчиков в Telegram канале, чате или боте",
    banner_image: "bots",
  },
  // tg_groupSpam: {
  //   banner_title: "Рассылка по групповым чатам",
  //   banner_caption: "Лучший способ продвижения вашего канала или сайта",
  //   banner_image: "groupMsg",
  // },
};
