import { Col } from "react-bootstrap";
import Swal from "sweetalert2";
import "./Order.css";

const Order = ({ order, unregistered }) => {
  let orderTypeName;
  let orderIcon;
  let socialNetwork;
  let status;
  let statusIcon;

  const formatDate = (date) => {
    if (unregistered) {
      return `${date.getDate()}.${String(date.getMonth()).padStart(
        2,
        "0"
      )}.${date.getFullYear()}`;
    }
    const dArr = date.substring(0, 10).split("-");
    return dArr[2] + "." + dArr[1] + "." + dArr[0];
  };

  const generateDetails = (order) => {
    const specialDetails = order[order.type];

    const rowP1 = `<div class="single-detail"><div class="row details-modal-title">`;
    const rowP2 = `</div><div class="row"><div class="details-modal-value">`;
    const rowP3 = `</div></div></div>`;

    let result =
      rowP1 +
      "ID" +
      rowP2 +
      (unregistered ? "Ожидает оплаты" : order._id) +
      rowP3;
    let title = "";
    let value = "";

    if (order.status === "Declined") {
      result += rowP1 + "Причина" + rowP2 + order.reason + rowP3;
    }

    for (const pair in specialDetails) {
      let skipLine = false;
      value = specialDetails[pair];
      switch (pair) {
        case "link":
          title = "Ссылка";
          break;
        case "url":
          title = "Ссылка";
          break;
        case "hours":
          title = "Часы";
          break;
        case "textFiles":
          if (specialDetails[pair].length === 0 && order.makeMessage) {
            value = "Заказано написание";
          }
          title = "Текст сообщения";
          break;
        case "mediaFiles":
          if (specialDetails[pair].length === 0) {
            skipLine = true;
          }
          title = "Изображение";
          break;
        case "usersList":
          if (order.findAudience) {
            value = "Заказан подбор";
          }
          title = "База для рассылки";
          break;
        case "groupList":
          if (order.findAudience) {
            value = "Заказан подбор";
          }
          title = "База для рассылки";
          break;
        case "groupsFrom":
          if (specialDetails[pair].length === 0 && !order.findAudience) {
            skipLine = true;
          } else if (order.findAudience) {
            value = "Заказан подбор";
          }
          title = "База для инвайтинга";
          break;
        case "usersListFrom":
          if (specialDetails[pair].length === 0) {
            skipLine = true;
          }
          title = "База для инвайтинга";
          break;
        case "groupTo":
          title = "Инвайт в";
          break;
        case "report":
          title = "Отчет";
          value =
            specialDetails[pair] === "Not yet completed"
              ? "В процессе"
              : specialDetails[pair];
          break;
        case "days":
          title = "Дней";
          break;
        case "timesPerDay":
          title = "Раз в день";
          break;
        case "channel":
          title = "Канал";
          break;
        case "startTime":
          title = "Дата начала";
          value = formatDate(specialDetails[pair]);
          break;
        case "daysCount":
          title = "Дни";
          break;
        case "minViewsPerHour":
          title = "Мin кол-во просмотров в час";
          break;
        case "maxViewsPerHour":
          title = "Max кол-во просмотров в час";
          break;
        case "country":
          title = "Язык просмотров";
          switch (order.tg_views.country) {
            case 1:
              value = "Русский";
              break;
            case 2:
              value = "Английский";
              break;
            case 0:
              value = "Все языки";
              break;
            case 16:
              value = "СНГ";
              break;
            default:
              value = "Ошибка";
          }
          break;
        default:
          skipLine = true;
          break;
      }
      if (!skipLine) result += rowP1 + title + rowP2 + value + rowP3;
    }

    if (order.comment) {
      result += rowP1 + "Комментарий" + rowP2 + order.comment + rowP3;
    }

    return "<div class='container'>" + result + "</div>";
  };

  generateDetails(order);

  if (order.type.substring(0, 2) === "tg") {
    socialNetwork = "Telegram";
  } else if (order.type.substring(0, 2) === "tt") {
    socialNetwork = "Tik Tok";
  }

  switch (order.status) {
    case "Queued up":
      if (unregistered) {
        status = "Ожидает оплаты";
      } else {
        status = "В очереди ";
      }
      statusIcon = "queue";
      break;
    case "Active":
      status = "В процессе";
      statusIcon = "loading";
      break;
    case "Completed":
      status = "Готов";
      statusIcon = "completed";
      break;
    case "Paused":
      status = "Приостановлен";
      statusIcon = "pause";
      break;
    case "Declined":
      status = "Отменен";
      statusIcon = "declined";
      break;
    default:
      break;
  }
  switch (order.type) {
    case "tg_spam":
      orderTypeName = "Рассылка сообщений";
      orderIcon = "msg";
      break;
    case "tg_groupSpam":
      orderTypeName = "Рассылка сообщений по группам";
      orderIcon = "groupMsg";
      break;
    case "tg_inviting":
      orderTypeName = "Инвайтинг";
      orderIcon = "inviting";
      break;
    case "tg_views":
      orderTypeName = "Просмотры";
      orderIcon = "views";
      break;
    case "tg_bots":
      orderTypeName = "Добавление ботов";
      orderIcon = "bots";
      break;
    case "tt":
      switch (order.tt.orderType) {
        case "SUBS":
          orderTypeName = "Подписчики";
          orderIcon = "groupMsg";
          break;
        case "LIKES":
          orderTypeName = "Лайки";
          orderIcon = "like";
          break;
        case "SHARES":
          orderTypeName = "Репосты";
          orderIcon = "share";
          break;
        case "VIEWS":
          orderTypeName = "Просмотры";
          orderIcon = "views";
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
  const handleDetails = () => {
    Swal.fire({
      title: "Детали",
      html: generateDetails(order),
      customClass: {
        popup: "details-modal-popup",
        title: "details-modal-top-title",
      },
    });
  };

  return (
    <>
      <Col md={3}>
        <div className="order-card">
          <div className="order-card-title">
            <img
              src={`${process.env.PUBLIC_URL}/images/${orderIcon}.svg`}
              alt={orderTypeName}
            />
            <div>{orderTypeName}</div>
          </div>
          <div className="order-card-content">
            <div className="order-card-content-row">
              <div>Статус:</div>
              <div className={`${order.status.replace(" ", "")}-color`}>
                {status}
                <img
                  src={`${process.env.PUBLIC_URL}/images/${statusIcon}.svg`}
                  alt={status}
                />
              </div>
            </div>
            <div className="order-card-content-row">
              <div>Соц. сеть:</div>
              <div>{socialNetwork}</div>
            </div>
            <div className="order-card-content-row">
              <div>Заказанное кол-во:</div>
              <div>{order.amount}</div>
            </div>
            <div className="order-card-content-row">
              <div>Дата заказа:</div>
              <div>
                {formatDate(unregistered ? new Date() : order.dateOrdered)}
              </div>
            </div>
          </div>
          <div className="order-card-button-row">
            <button className="order-card-button" onClick={handleDetails}>
              ДЕТАЛИ
            </button>
          </div>
        </div>
      </Col>
    </>
  );
};

export default Order;
