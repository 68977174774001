import { Col, Container, Row } from "react-bootstrap";

const WhyUs = () => {
  return (
    <Container
      fluid
      className="service-content-container contact-banner-container"
    >
      <Row className="d-flex justify-content-center align-items-center">
        <Col md={10}>
          <div className="why-us-container">
            <div className="why-us-title">Почему нам доверяют? </div>
            <div className="why-us-text">
              Наш сервис работает в эффективном и быстром ключе и предоставляет
              разнообразные услуги с конверсией до 90% в зависимости
              непосредственно от услуги и прочих обсуждаемых факторов.
              Используются различные инструменты продвижения - от накрутки ботов
              до инвайтинга.
              <br />
              <br /> За дополнительную плату мы можем составить портрет вашей
              целевой аудитории и написать рекламное сообщение. Это сэкономит
              ваше время, а также повысит эффективность вашего информационного
              ресурса, блога, бизнеса или проекта. Открывайте новые горизонты и
              реализуйте самые смелые стратегии!
              <br />
              <br /> Звоните и пишите: дружелюбные и отзывчивые операторы
              реагируют на все комментарии и запросы, всегда готовы помочь с
              оформлением заказа.
              <br />
              <br /> Закажите продвижение у нас! Будьте на шаг впереди :)
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default WhyUs;
