import { Carousel, Col, Container, Row } from "react-bootstrap";
import { reviews } from "../../../assets/reviews/reviews";
import { Link } from "react-router-dom";
import "./Reviews.css";
import { useEffect, useState } from "react";

const Reviews = () => {
  const reviewChunks = [];
  const [selectedReviewSession, setSelectedReviewSession] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  let index;
  if (screenWidth >= 768) {
    index = 0;
    while (reviews.slice(index, index + 4).length - index >= 0) {
      reviewChunks.push(reviews.slice(index, index + 4));
      index += 4;
    }
  } else {
    for (let i in reviews) {
      reviewChunks.push([reviews[i]]);
    }
  }

  const detectSize = () => {
    setScreenWidth(window.innerWidth);
  };

  const handleSelect = (selectedItem) => {
    setSelectedReviewSession(selectedItem);
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [screenWidth]);

  return (
    <>
      <Container className="reviews-page-container">
        <Row className="review-row">
          <Col>
            <Carousel
              onSelect={handleSelect}
              activeIndex={selectedReviewSession}
              controls={false}
              indicators={false}
            >
              {reviewChunks.map((reviewChunk, index) => {
                return (
                  <Carousel.Item key={index} className="reviews-carousel-item">
                    <Row>
                      {reviewChunk.map((review) => {
                        return (
                          <Col
                            md={6}
                            key={review.id}
                            className="review-card-col"
                          >
                            <div className="review-card">
                              <div className="review-card-top-row">
                                <div
                                  className="user-settings-icon review-card-icon"
                                  style={{ "--bg": `${review.color}` }}
                                >
                                  {review.name &&
                                    review.name.slice(0, 1).toUpperCase()}
                                </div>
                                <div>
                                  <div className="review-card-name">
                                    {review.name}
                                  </div>
                                  <div className="review-card-service">
                                    Услуга:{" "}
                                    <Link
                                      className="review-card-link"
                                      to={review.link}
                                    >
                                      {review.service}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="review-card-bottom-row">
                                {review.text}
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </Carousel.Item>
                );
              })}
              <Carousel.Item key={index} className="reviews-carousel-item">
                <Row className="write-a-review-row">
                  <Col md={12} className="review-card-col">
                    <div className="write-a-review-card">
                      <div className="write-a-review-title">
                        Оставьте свой отзыв и получите <b>промокод</b> на плюс к
                        пополнению баланса!
                      </div>
                      <a
                        className="button-type-1"
                        href="mailto:support@pavelandco.com?subject=Отзыв на сайте"
                        rel="noreferrer"
                        target="_blank"
                      >
                        НАПИСАТЬ
                      </a>
                    </div>
                  </Col>
                </Row>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="review-section-controller-col">
            <img
              src={`${process.env.PUBLIC_URL}/images/arrowLeft.svg`}
              alt="arrow-left"
              className="review-section-arrow"
              onClick={() => {
                if (selectedReviewSession === 0) {
                  setSelectedReviewSession(reviewChunks.length);
                } else {
                  setSelectedReviewSession((prev) => prev - 1);
                }
              }}
            />
            {[...reviewChunks, []].map((c, index) => {
              return (
                <div
                  className={`review-carousel-section-indicator ${
                    index === selectedReviewSession
                      ? "acrive-review-chunk"
                      : undefined
                  }`}
                  onClick={() => {
                    setSelectedReviewSession(index);
                  }}
                ></div>
              );
            })}
            <img
              src={`${process.env.PUBLIC_URL}/images/arrowRight.svg`}
              alt="arrow-right"
              className="review-section-arrow"
              onClick={() => {
                if (selectedReviewSession === reviewChunks.length) {
                  setSelectedReviewSession(0);
                } else {
                  setSelectedReviewSession((prev) => prev + 1);
                }
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Reviews;
