import { Accordion, Col, Container, Row } from "react-bootstrap";
import "./ServiceOptionBar.css";
import { Link, useLocation } from "react-router-dom";

const ServiceOptionBar = () => {
  const sampleLocation = useLocation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const telegramData = [
    {
      logo: "views",
      alt: "tg-views",
      title: "Накрутка Просмотров",
      link: "telegram/views",
    },
    {
      logo: "bots",
      alt: "tg-bots",
      title: "Накрутка Ботов",
      link: "telegram/bots",
    },
    // {
    //   logo: "groupMsg",
    //   alt: "tg-groupMsg",
    //   title: "Рассылка по групповым чатам",
    //   link: "telegram/group",
    // },
    {
      logo: "msg",
      alt: "tg-msg",
      title: "Рассылка в личные сообщения",
      link: "telegram/message",
    },
    {
      logo: "inviting",
      alt: "tg-invite",
      title: "Инвайтинг",
      link: "telegram/inviting",
    },
  ];

  const tiktokData = [
    {
      logo: "like",
      alt: "tt-likes",
      title: "Лайки",
      link: "tiktok/likes",
    },
    {
      logo: "groupMsg",
      alt: "tg-subs",
      title: "Подписчики",
      link: "tiktok/subs",
    },
    {
      logo: "share",
      alt: "tg-share",
      title: "Репосты",
      link: "tiktok/share",
    },
    {
      logo: "views",
      alt: "tt-views",
      title: "Просмотры",
      link: "tiktok/views",
    },
  ];

  return (
    <>
      <Container className="service-options-container">
        <Row className="service-options-main-row">
          <Col className="service-options-title">Каталог yслуг</Col>
          <Accordion
            defaultActiveKey={["0", "1"]}
            alwaysOpen
            className="service-types-accordion"
            flush
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <img
                  src={`${process.env.PUBLIC_URL}/images/telegram.svg`}
                  alt="tg-logo"
                />
                <span className="service-network">Telegram</span>
              </Accordion.Header>
              <Accordion.Body>
                <ul className="service-types">
                  {telegramData.map((service) => {
                    return (
                      <Link
                        onClick={scrollToTop}
                        key={service.alt}
                        to={service.link}
                        className={
                          sampleLocation.pathname.includes(service.link)
                            ? "active-service-type"
                            : undefined
                        }
                      >
                        <li>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/${service.logo}.svg`}
                            alt={service.alt}
                          />
                          <div>{service.title}</div>
                        </li>
                      </Link>
                    );
                  })}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="tiktokItem">
              <Accordion.Header>
                <img
                  src={`${process.env.PUBLIC_URL}/images/tiktok.svg`}
                  alt="tt-logo"
                />
                <span className="service-network">Tik Tok</span>
              </Accordion.Header>
              <Accordion.Body>
                <ul className="service-types">
                  {tiktokData.map((service) => {
                    return (
                      <Link
                        onClick={scrollToTop}
                        key={service.alt}
                        to={service.link}
                        className={
                          sampleLocation.pathname.includes(service.link)
                            ? "active-service-type"
                            : undefined
                        }
                      >
                        <li>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/${service.logo}.svg`}
                            alt={service.alt}
                          />
                          <div>{service.title}</div>
                        </li>
                      </Link>
                    );
                  })}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
      </Container>
    </>
  );
};

export default ServiceOptionBar;
