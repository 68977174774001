import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import "./NewPass.css";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

const NewPass = () => {
  const { hash } = useParams();
  const [info, setInfo] = useState();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingChange, setLoadingChange] = useState();
  const [passVal, setPassVal] = useState();

  const navigate = useNavigate();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    showCloseButton: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    fetch(`https://api.pavelandco.com/api/user/newpass/check/${hash}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          setInfo(response.status);
          return response.json();
        }
        setInfo(response.status);
        throw response;
      })
      .then((udata) => {
        setUserData(udata);
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [hash]);

  const handleNewPass = () => {
    setLoadingChange(true);

    const submitData = {
      email: userData.email,
      pass: passVal,
      hash: userData.hash,
    };
    const requestOptions = {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(submitData),
    };
    fetch(`https://api.pavelandco.com/api/user/newpass/change`, requestOptions)
      .then((response) => {
        setLoadingChange(false);
        if (!response.ok) {
          Toast.fire({
            icon: "error",
            title:
              "Произошла ошибка, попробуйте снова или обратитесь в поддержку",
          });
          throw response;
        }
        return;
      })
      .catch((err) => {
        throw err;
      })
      .then(() => {
        Toast.fire({
          icon: "success",
          title: "Пароль успешно изменен",
        });

        navigate("/");
      });
  };

  return (
    <>
      <Container className="new-pass-page-container">
        {loading ? (
          <>
            <Row>
              <Col>
                <img
                  src={`${process.env.PUBLIC_URL}/images/logo.svg`}
                  alt="logo"
                  className="loading-logo"
                />
              </Col>
            </Row>
            <Row>
              <Col className="error-page-title">
                Проверяем ссылку, cекундочку...
              </Col>
            </Row>
          </>
        ) : (
          (() => {
            switch (info) {
              case 200:
                return (
                  <>
                    <Row>
                      <Col className="error-page-title">
                        Введите новый пароль
                      </Col>
                    </Row>
                    <Row>
                      <Col className="error-page-content new-pass-caption">
                        Пароль будет изменен сразу после отправки формы
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <input
                          value={passVal}
                          onChange={(e) => setPassVal(e.target.value)}
                          type="text"
                          className="input-type-1 new-pass-input"
                          name="new-pass"
                          id="new-pass"
                          placeholder="Новый пароль"
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <button
                          className={`button-type-1 ${
                            loadingChange ? "axios-loading" : undefined
                          }`}
                          disabled={loadingChange}
                          onClick={handleNewPass}
                        >
                          Сменить пароль
                        </button>
                      </Col>
                    </Row>
                  </>
                );
              case 404:
                return (
                  <>
                    <Row>
                      <Col className="error-page-title">Ссылка не найдена</Col>
                    </Row>
                    <Row>
                      <Col className="error-page-content new-pass-caption">
                        Подобная ссылка не найдена, или уже использованна
                      </Col>
                    </Row>
                  </>
                );
              case 410:
                return (
                  <>
                    <Row>
                      <Col className="error-page-title">Ссылка устарела</Col>
                    </Row>
                    <Row>
                      <Col className="error-page-content new-pass-caption">
                        Если вам все еще требуется смена пароля, то запросите
                        новую ссылку
                      </Col>
                    </Row>
                  </>
                );
              default:
                return (
                  <>
                    <Row>
                      <Col className="error-page-title">Неизвестная ошибка</Col>
                    </Row>
                    <Row>
                      <Col className="error-page-content new-pass-caption">
                        Произошла неизвестная ошибка, обратитесь в поддержку
                      </Col>
                    </Row>
                  </>
                );
            }
          })()
        )}
      </Container>
    </>
  );
};

export default NewPass;
