import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { calculatePriceBots } from "../../Service/CalculatePrice";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { processOrder } from "../../../../Store/Utils/thunkCreators";

const TTBots = () => {
  const token = Cookies.get("token");

  const navigate = useNavigate();

  const [amount, setAmount] = useState(2500);
  const [loading, setLoading] = useState(false);
  const [hours, setHours] = useState(30);

  let formData = { tt: {} };

  const price = calculatePriceBots(amount);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    showCloseButton: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const firstHint = {
    title: "Количество",
    text: "Выберите количество подписчиков и часов. Чем меньше часов, тем выше скорость. 0 - Для максимальной скорости.",
  };
  const secondHint = {
    title: "Оставьте ссылку",
    text: `Оставьте ссылку на аккаунт`,
  };
  const thirdHint = {
    title: "Оставьте комментарий",
    text: `Если вы хотите ещё что-нибудь добавить, то вы можете написать комментарий к заказу.`,
  };

  const priceHintContent = {
    title: "Цена",
    html: ` <div class="container-fluid pricing-hint-container">
        <div class="row">
          <div class="col-11 ${
            amount < 2001 && "price-gap-selected"
          }">1-2000 Ботов - 0.7 руб. за бота </div>
          <div class="col-1">${
            amount < 2001
              ? ` <svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <div class="row">
          <div class="col-11 ${
            amount >= 2001 && amount < 3001 && "price-gap-selected"
          }">2001-3000 Ботов - 0.45 руб. за бота </div>
          <div class="col">${
            amount >= 2001 && amount < 3001
              ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <div class="row">
          <div class="col-11 ${
            amount >= 3001 && amount < 5001 && "price-gap-selected"
          }">3001-5000 Ботов - 0.35 руб. за бота</div>
          <div class="col">${
            amount >= 3001 && amount < 5001
              ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <div class="row">
          <div class="col-11 ${
            amount >= 5001 && amount < 10001 && "price-gap-selected"
          }">5001-10000 Ботов - 0.3 руб. за бота</div>
          <div class="col">${
            amount >= 5001 && amount < 10001
              ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <div class="row">
          <div class="col-11 ${
            amount >= 10001 && "price-gap-selected"
          }">10000+ Ботов - 0.25 руб. за бота</div>
          <div class="col">${
            amount >= 10001
              ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
      </div>`,
  };

  const handleHint = (content) => {
    Swal.fire({
      title: content.title,
      text: content.text,
      html: content.html,
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        popup: "pop-up-type-1 hint-pop-up",
        title: "pop-up-type-1-title",
        closeButton: "pop-up-type-1-close",
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    formData.amount = amount;
    formData.comment = e.target.comment.value;
    formData.type = "tt";
    formData.status = "Queued up";
    formData.dateOrdered = new Date();
    formData.priceRu = parseFloat(price);
    formData.priceEn = 0;
    formData.tt.url = e.target.link.value;
    formData.tt.orderType = "SUBS";
    formData.tt.hours = hours;

    axios.defaults.headers.post["Authorization"] = `jwt ${token}`;
    setLoading(true);
    if (!token) {
      setLoading(false);
      return navigate("/service/pay", {
        state: {
          formData,
        },
      });
    }
    try {
      const { data } = await axios.post(
        `https://api.pavelandco.com/api/user/order/ru`,
        formData
      );
      Toast.fire({
        icon: "success",
        title: "Заказ успешно создан",
      });
      dispatch(processOrder(data));
      return navigate("/service/orders");
    } catch (error) {
      if (error.request.status === 406) {
        Toast.fire({
          icon: "error",
          title: `Недостаточно средств на балансе  (${
            price - user.balanceRu
          } р)`,
        });
      } else if (error.request.status === 401) {
        Toast.fire({
          icon: "error",
          title: `Отсутствует регистрация`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: `Ошибка - ${error}`,
        });
      }
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/1.svg`} alt="one" />
            <div className="order-hint-caption">
              Выберите количество подписчиков и часов. Чем меньше часов, тем
              выше скорость. 0 - Для максимальной скорости.
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-col">
              <div className="order-input-title">
                <img
                  src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                  alt={"question"}
                  onClick={() => handleHint(firstHint)}
                  className="hide-on-desktop"
                />
                Количество
              </div>
              <div className="order-input-title-hint hide-on-mobile">
                Подписчиков
              </div>
              <div className="order-input-range-field double-range-input mb-3">
                <input
                  type="range"
                  min={100}
                  max={40000}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                  step={amount < 5000 ? 500 : 1000}
                />
                <div className="range-input-numer-field">
                  <input
                    type="number"
                    min={100}
                    max={40000}
                    value={amount}
                    required
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <div className="order-range-input-caption">Подписчиков</div>
                </div>
              </div>
              <div className="order-input-title-hint hide-on-mobile">Часов</div>
              <div className="order-input-range-field double-range-input">
                <input
                  type="range"
                  min={0}
                  max={200}
                  value={hours}
                  onChange={(e) => setHours(e.target.value)}
                  required
                  step={amount < 5000 ? 5 : 10}
                />
                <div className="range-input-numer-field">
                  <input
                    type="number"
                    min={0}
                    max={200}
                    value={hours}
                    required
                    onChange={(e) => setHours(e.target.value)}
                  />
                  <div className="order-range-input-caption">Часов</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/2.svg`} alt="two" />
            <div className="order-hint-caption">Оставьте ссылку аккаунт</div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-col">
              <div className="order-input-title">
                <img
                  src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                  alt={"question"}
                  onClick={() => handleHint(secondHint)}
                  className="hide-on-desktop"
                />
                Оставьте ссылку
              </div>
            </div>
            <input
              required
              type="text"
              name="link"
              className={`input-type-2`}
              placeholder={"Ссылка..."}
            />
          </Col>
        </Row>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/3.svg`} alt="three" />
            <div className="order-hint-caption">
              Если вы хотите ещё что-нибудь добавить, то вы можете написать
              комментарий к заказу.
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-col">
              <div className="order-input-title">
                <img
                  src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                  alt={"question"}
                  onClick={() => handleHint(thirdHint)}
                  className="hide-on-desktop"
                />
                Оставьте комментарий
              </div>
              <input
                type="text"
                name="comment"
                className={`input-type-2`}
                placeholder="Ваше сообщение..."
              />
            </div>
          </Col>
        </Row>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/4.svg`} alt="four" />
            <div className="order-hint-caption">
              Создавайте заказ и наблюдайте за его статусом в личном кабинете :)
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-row order-create-order-row">
              <button
                className={`button-type-1 ${
                  loading ? "axios-loading" : undefined
                }`}
                type="submit"
                disabled={loading}
              >
                Сделать заказ
              </button>
              <div className="order-input-title">Итого: </div>
              <div className="order-extra-service-input-col">
                <div className="order-extra-service-caption">
                  {isNaN(price) ? "Ошибка" : price}
                  {" руб."}
                  <img
                    src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                    alt={"question"}
                    onClick={() => handleHint(priceHintContent)}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default TTBots;
