// ACTIONS

import { withdrawBalance } from "./Utils/reducerFunctions";

const GET_USER = "GET_USER";
const SET_FETCHING_STATUS = "SET_FETCHING_STATUS";
const WITHDRAW_BALANCE = "WITHDRAW_BALANCE";

// ACTION CREATORS

export const gotUser = (user) => {
  return {
    type: GET_USER,
    user,
  };
};

export const setFetchingStatus = (isFetching) => ({
  type: SET_FETCHING_STATUS,
  isFetching,
});

export const withdrawUserBalance = (amount) => ({
  type: WITHDRAW_BALANCE,
  amount,
});

// REDUCER

const reducer = (state = { isFetching: true }, action) => {
  switch (action.type) {
    case GET_USER:
      return action.user;
    case SET_FETCHING_STATUS:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case WITHDRAW_BALANCE:
      return withdrawBalance(state, action.amount);
    default:
      return state;
  }
};

export default reducer;
