export function calculatePriceSpam(
  type,
  amount,
  makeMessage,
  findAudience,
  language = "RU"
) {
  let price;
  let rate;
  switch (type) {
    case "tg_spam":
      switch (true) {
        case amount > 10000:
          if (language === "RU") {
            rate = 2.1;
            break;
          } else if (language === "EN") {
            rate = 0.02;
            break;
          } else return "Language Error";
        case amount > 5000:
          if (language === "RU") {
            rate = 2.3;
            break;
          } else if (language === "EN") {
            rate = 0.025;
            break;
          } else return "Language Error";
        case amount > 1000:
          if (language === "RU") {
            rate = 2.5;
            break;
          } else if (language === "EN") {
            rate = 0.03;
            break;
          } else {
            return "Language Error";
          }
        case amount >= 500:
          if (language === "RU") {
            rate = 3;
            break;
          } else if (language === "EN") {
            rate = 0.035;
            break;
          } else return "Language Error";
        default:
          break;
      }
      if (language === "RU") {
        makeMessage ? (rate += 0.5) : (rate += 0);
        findAudience ? (rate += 0.5) : (rate += 0);
        price = amount * rate;
        return price.toFixed(2);
      } else if (language === "EN") {
        makeMessage ? (rate += 0.007) : (rate += 0);
        findAudience ? (rate += 0.007) : (rate += 0);
        price = amount * rate;
        return price.toFixed(2);
      }
      break;
    case "tg_groupSpam":
      switch (true) {
        case amount > 10000:
          if (language === "RU") {
            rate = 4;
            break;
          } else if (language === "EN") {
            rate = 0.055;
            break;
          } else return "Language Error";
        case amount > 5000:
          if (language === "RU") {
            rate = 5;
            break;
          } else if (language === "EN") {
            rate = 0.07;
            break;
          } else return "Language Error";
        case amount >= 500:
          if (language === "RU") {
            rate = 6;
            break;
          } else if (language === "EN") {
            rate = 0.082;
            break;
          } else return "Language Error";
        default:
          break;
      }
      if (language === "RU") {
        makeMessage ? (rate += 0.5) : (rate += 0);
        findAudience ? (rate += 0.5) : (rate += 0);
        price = amount * rate;
        return price.toFixed(2);
      } else if (language === "EN") {
        makeMessage ? (rate += 0.007) : (rate += 0);
        findAudience ? (rate += 0.007) : (rate += 0);
        price = amount * rate;
        return price.toFixed(2);
      }
      break;
    case "wa_spam":
      switch (true) {
        case amount > 10000:
          if (language === "RU") {
            rate = 1.8;
            break;
          } else if (language === "EN") {
            rate = 0.025;
            break;
          } else return "Language Error";
        case amount > 5000:
          if (language === "RU") {
            rate = 2;
            break;
          } else if (language === "EN") {
            rate = 0.028;
            break;
          } else return "Language Error";
        case amount >= 1000:
          if (language === "RU") {
            rate = 2.2;
            break;
          } else if (language === "EN") {
            rate = 0.03;
            break;
          } else return "Language Error";
        default:
          break;
      }
      if (language === "RU") {
        makeMessage ? (rate += 0.5) : (rate += 0);
        price = amount * rate;
        return price.toFixed(2);
      } else if (language === "EN") {
        makeMessage ? (rate += 0.007) : (rate += 0);
        price = amount * rate;
        return price.toFixed(2);
      }
      break;
    case "wa_spam_with_image":
      switch (true) {
        case amount > 10000:
          if (language === "RU") {
            rate = 2;
            break;
          } else if (language === "EN") {
            rate = 0.028;
            break;
          } else return "Language Error";
        case amount > 5000:
          if (language === "RU") {
            rate = 2.2;
            break;
          } else if (language === "EN") {
            rate = 0.03;
            break;
          } else return "Language Error";
        case amount >= 1000:
          if (language === "RU") {
            rate = 2.5;
            break;
          } else if (language === "EN") {
            rate = 0.035;
            break;
          } else return "Language Error";
        default:
          break;
      }
      if (language === "RU") {
        makeMessage ? (rate += 0.5) : (rate += 0);
        price = amount * rate;
        return price.toFixed(2);
      } else if (language === "EN") {
        makeMessage ? (rate += 0.007) : (rate += 0);
        price = amount * rate;
        return price.toFixed(2);
      }
      break;
    case "vi_spam":
      switch (true) {
        case amount > 500000:
          if (language === "RU") {
            rate = 0.48;
            break;
          } else if (language === "EN") {
            rate = 0.0065;
            break;
          } else return "Language Error";
        case amount > 50000:
          if (language === "RU") {
            rate = 0.52;
            break;
          } else if (language === "EN") {
            rate = 0.0071;
            break;
          } else return "Language Error";
        case amount >= 1000:
          if (language === "RU") {
            rate = 0.6;
            break;
          } else if (language === "EN") {
            rate = 0.0082;
            break;
          } else return "Language Error";
        default:
          break;
      }
      if (language === "RU") {
        makeMessage ? (rate += 0.5) : (rate += 0);
        price = amount * rate;
        return price.toFixed(2);
      } else if (language === "EN") {
        makeMessage ? (rate += 0.007) : (rate += 0);
        price = amount * rate;
        return price.toFixed(2);
      }
      break;
    default:
      return "Order Type Error";
  }
}

export function calculatePriceInviting(amount, findAudience, language = "RU") {
  let price;
  let rate;
  switch (true) {
    case amount > 10000:
      if (language === "RU") {
        rate = 1.6;
        break;
      } else if (language === "EN") {
        rate = 0.022;
        break;
      } else return "Language Error";
    case amount > 5000:
      if (language === "RU") {
        rate = 1.8;
        break;
      } else if (language === "EN") {
        rate = 0.025;
        break;
      } else return "Language Error";
    case amount > 1000:
      if (language === "RU") {
        rate = 2;
        break;
      } else if (language === "EN") {
        rate = 0.03;
        break;
      } else {
        return "Language Error";
      }
    case amount >= 500:
      if (language === "RU") {
        rate = 2.5;
        break;
      } else if (language === "EN") {
        rate = 0.035;
        break;
      } else return "Language Error";
    default:
      break;
  }
  if (language === "RU") {
    findAudience ? (rate += 0.5) : (rate += 0);
    price = amount * rate;
    return price.toFixed(2);
  } else if (language === "EN") {
    findAudience ? (rate += 0.007) : (rate += 0);
    price = amount * rate;
    return price.toFixed(2);
  }
}

export function calculatePriceViews(amount, days, language = "RU") {
  let price;
  let rate;
  switch (true) {
    case amount > 45000:
      if (language === "RU") {
        rate = 460;
        break;
      } else if (language === "EN") {
        rate = 6;
        break;
      } else return "Language Error";
    case amount > 40000:
      if (language === "RU") {
        rate = 440;
        break;
      } else if (language === "EN") {
        rate = 5.85;
        break;
      } else return "Language Error";
    case amount > 35000:
      if (language === "RU") {
        rate = 420;
        break;
      } else if (language === "EN") {
        rate = 5.6;
        break;
      } else return "Language Error";
    case amount > 30000:
      if (language === "RU") {
        rate = 400;
        break;
      } else if (language === "EN") {
        rate = 5.35;
        break;
      } else return "Language Error";
    case amount > 25000:
      if (language === "RU") {
        rate = 380;
        break;
      } else if (language === "EN") {
        rate = 5.15;
        break;
      } else return "Language Error";
    case amount > 20000:
      if (language === "RU") {
        rate = 360;
        break;
      } else if (language === "EN") {
        rate = 4.9;
        break;
      } else return "Language Error";
    case amount > 15000:
      if (language === "RU") {
        rate = 340;
        break;
      } else if (language === "EN") {
        rate = 4.59;
        break;
      } else return "Language Error";
    case amount > 10000:
      if (language === "RU") {
        rate = 320;
        break;
      } else if (language === "EN") {
        rate = 4.32;
        break;
      } else return "Language Error";
    case amount > 5000:
      if (language === "RU") {
        rate = 300;
        break;
      } else if (language === "EN") {
        rate = 4.05;
        break;
      } else return "Language Error";
    default:
      if (language === "RU") {
        rate = 167;
        break;
      } else if (language === "EN") {
        rate = 2.25;
        break;
      } else return "Language Error";
  }
  price = days * rate;
  if (days >= 30) {
    price /= 2;
  }

  return price.toFixed(2);
}

export function calculatePriceBots(amount, language = "RU") {
  let price;
  let rate;
  switch (true) {
    case amount > 10000:
      if (language === "RU") {
        rate = 0.25;
        break;
      } else if (language === "EN") {
        rate = 0.0035;
        break;
      } else return "Language Error";
    case amount > 5000:
      if (language === "RU") {
        rate = 0.3;
        break;
      } else if (language === "EN") {
        rate = 0.0045;
        break;
      } else return "Language Error";
    case amount > 3000:
      if (language === "RU") {
        rate = 0.35;
        break;
      } else if (language === "EN") {
        rate = 0.005;
        break;
      } else return "Language Error";
    case amount > 2000:
      if (language === "RU") {
        rate = 0.45;
        break;
      } else if (language === "EN") {
        rate = 0.006;
        break;
      } else return "Language Error";
    default:
      if (language === "RU") {
        rate = 0.7;
        break;
      } else if (language === "EN") {
        rate = 0.01;
        break;
      } else return "Language Error";
  }
  price = amount * rate;
  return price.toFixed(2);
}

export function calculatePriceTtLikesShares(amount, language = "RU") {
  let price;
  let rate;
  switch (true) {
    case amount > 10000:
      if (language === "RU") {
        rate = 1.25;
        break;
      } else if (language === "EN") {
        rate = 0.0175;
        break;
      } else return "Language Error";
    case amount > 5000:
      if (language === "RU") {
        rate = 1.5;
        break;
      } else if (language === "EN") {
        rate = 0.0225;
        break;
      } else return "Language Error";
    case amount > 3000:
      if (language === "RU") {
        rate = 1.75;
        break;
      } else if (language === "EN") {
        rate = 0.025;
        break;
      } else return "Language Error";
    case amount > 2000:
      if (language === "RU") {
        rate = 2.25;
        break;
      } else if (language === "EN") {
        rate = 0.03;
        break;
      } else return "Language Error";
    default:
      if (language === "RU") {
        rate = 4.5;
        break;
      } else if (language === "EN") {
        rate = 0.05;
        break;
      } else return "Language Error";
  }
  price = amount * rate;
  return price.toFixed(2);
}

export function calculatePriceTTViews(amount, language = "RU") {
  let price;
  let rate;
  switch (true) {
    case amount > 10000:
      if (language === "RU") {
        rate = 0.01;
        break;
      } else if (language === "EN") {
        rate = 0.0035;
        break;
      } else return "Language Error";
    case amount > 5000:
      if (language === "RU") {
        rate = 0.05;
        break;
      } else if (language === "EN") {
        rate = 0.0045;
        break;
      } else return "Language Error";
    case amount > 3000:
      if (language === "RU") {
        rate = 0.1;
        break;
      } else if (language === "EN") {
        rate = 0.005;
        break;
      } else return "Language Error";
    case amount > 2000:
      if (language === "RU") {
        rate = 0.15;
        break;
      } else if (language === "EN") {
        rate = 0.006;
        break;
      } else return "Language Error";
    default:
      if (language === "RU") {
        rate = 0.2;
        break;
      } else if (language === "EN") {
        rate = 0.01;
        break;
      } else return "Language Error";
  }
  price = amount * rate;
  return price.toFixed(2);
}
