import { useEffect } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./Home.css";
import Reviews from "./Reviews/Reviews";
import Tree from "./Tree/Tree";
const Home = ({ leadSource }) => {
  useEffect(() => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 6000,
      timerProgressBar: true,
      showCloseButton: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    if (leadSource === "ydpro") {
      Toast.fire({
        icon: "info",
        title: `Промокод на +10% к балансу на первое пополнение:\n\n d212bs`,
      });
    }
  }, [leadSource]);
  return (
    <>
      <Container className="home-page-container" fluid>
        <Row className="home-page-start-row">
          <Col md={4} className=" hide-on-mobile hide-on-medium">
            <img
              src={`${process.env.PUBLIC_URL}/images/landingGif.gif`}
              alt="landingGif"
              className="landing-gif"
            />
          </Col>
          <Col xs={12} md={8}>
            <div className="landing-title">Продвижение от профессионалов</div>
            <div className="landing-start-caption">
              Специалисты нашего сервиса сделают все возможное, чтобы повысить
              вовлеченность аудитории и увеличить количество подписчиков.
              <br />
              Мы продвигаем интересный и полезный контент для социальных сетей.
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/images/landingGif.gif`}
              alt="landingGif"
              className="landing-gif hide-on-desktop"
            />
            <div className="landing-start-bottom-row">
              <a
                href="javascript:jivo_api.open();"
                className="width-fix-for-mid-sized-devices-hide"
              >
                <button className="orders-top-up-button">ЗАДАТЬ ВОПРОС</button>
              </a>
              <div className="landing-adv-col">
                <div className="single-adv ">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/adv1.svg`}
                    alt="adv1"
                  />
                  <div className="adv-text">
                    Индивидуальный подход к каждому клиенту.
                  </div>
                </div>
                <div className="single-adv">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/adv2.svg`}
                    alt="adv2"
                  />
                  <div className="adv-text">
                    Качественный контент и вовлечение “живых” читателей.
                  </div>
                </div>
                <div className="single-adv">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/adv3.svg`}
                    alt="adv3"
                  />
                  <div className="adv-text">
                    Безопасные способы продвижения ресурса.
                  </div>
                </div>
                <div className="single-adv hide-on-desktop hide-on-medium">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/adv1.svg`}
                    alt="adv1 "
                  />
                  <div className="adv-text">
                    Индивидуальный подход к каждому клиенту.
                  </div>
                </div>
                <div className="single-adv hide-on-desktop hide-on-medium">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/adv2.svg`}
                    alt="adv2 "
                  />
                  <div className="adv-text">
                    Качественный контент и вовлечение “живых” читателей.
                  </div>
                </div>
                <div className="single-adv hide-on-desktop hide-on-medium">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/adv3.svg`}
                    alt="adv3 "
                  />
                  <div className="adv-text">
                    Безопасные способы продвижения ресурса.
                  </div>
                </div>
              </div>
            </div>
            <a
              href="javascript:jivo_api.open();"
              className="width-fix-for-mid-sized-devices-show"
            >
              <button className="orders-top-up-button">ЗАДАТЬ ВОПРОС</button>
            </a>
          </Col>
        </Row>
        <Row>
          <Tree />
        </Row>
        <Row className="benefits-container">
          <Col sm={7}>
            <div className="benefits-card first-benefit-card">
              <div className="benefits-card-text-content">
                <div className="benefits-card-title">
                  Хотите обрести популярность в своем деле? Мы поможем!
                </div>
                <div className="benefits-card-text">
                  Привлекайте новых клиентов и повышайте доверие аудитории к
                  своему бизнесу с помощью накрутки и множества других
                  инструментов. Высокая активность в социальных сетях позволяет
                  эффективно продвигать продукт или бренд.
                </div>
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/images/benefitImage1.svg`}
                alt="benefitImage1"
              />
            </div>
          </Col>
          <Col sm={5}>
            <div className="benefits-card second-benefit-card">
              <div className="benefits-card-text-content">
                <div className="benefits-card-text">
                  Все заказы выполняются оперативно и качественно. Мы соблюдаем
                  дедлайны и учитываем индивидуальные особенности. Служба
                  поддержки клиентов разрешает любые вопросы, если что-то пошло
                  не так.
                  <br />
                  <br />
                  Работаем круглосуточно и без выходных.
                </div>
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/images/benefitImage2.svg`}
                alt="benefitImage2"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="why-us-container">
              <div className="why-us-title">Почему нам доверяют? </div>
              <div className="why-us-text">
                Наш сервис работает в эффективном и быстром ключе и
                предоставляет разнообразные услуги с конверсией до 90% в
                зависимости непосредственно от услуги и прочих обсуждаемых
                факторов. Используются различные инструменты продвижения - от
                накрутки ботов до инвайтинга.
                <br />
                <br /> За дополнительную плату мы можем составить портрет вашей
                целевой аудитории и написать рекламное сообщение. Это сэкономит
                ваше время, а также повысит эффективность вашего информационного
                ресурса, блога, бизнеса или проекта. Открывайте новые горизонты
                и реализуйте самые смелые стратегии!
                <br />
                <br /> Звоните и пишите: дружелюбные и отзывчивые операторы
                реагируют на все комментарии и запросы, всегда готовы помочь с
                оформлением заказа.
                <br />
                <br /> Закажите продвижение у нас! Будьте на шаг впереди :)
              </div>
            </div>
          </Col>
          <Col md={6}>
            <Reviews />
          </Col>
        </Row>
        <Row className="how-to-order-container">
          <Col>
            <div className="benefits-card first-benefit-card">
              <div className="how-to-order-title">
                Как заказать продвижение?
              </div>
              <div className="how-to-order-content">
                <div className="how-to-order-step">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/chose1.svg`}
                    alt="chose1"
                  />
                  <div className="how-to-order-step-title">Выберите услугу</div>
                  <div className="how-to-order-step-text">
                    У нас большой спектр услуг по каждой соц. сети.
                  </div>
                </div>
                <div className="how-to-order-step">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/quantity.svg`}
                    alt="quantity"
                  />
                  <div className="how-to-order-step-title">
                    Укажите количество
                  </div>
                  <div className="how-to-order-step-text">
                    Например, количество подписчиков для аккаунта.
                  </div>
                </div>
                <div className="how-to-order-step">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/pay1.svg`}
                    alt="pay1"
                  />
                  <div className="how-to-order-step-title">Оплатите заказ</div>
                  <div className="how-to-order-step-text">
                    Банковской картой или электронным кошельком.
                  </div>
                </div>
                <div className="how-to-order-step">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/heart1.svg`}
                    alt="heart1"
                  />
                  <div className="how-to-order-step-title">Наслаждайтесь</div>
                  <div className="how-to-order-step-text">
                    Отслеживайте статус заказа в личном кабинете.
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="blog-preview-container">
          <Col xs={12} md={6} className="blog-preview-col">
            <Link
              to={`/blog/advertising-in-telegram`}
              className="no-link-decoration"
            >
              <div className="benefits-card first-blog-post-preview">
                <div className="blog-post-preview-title">
                  <div className="blog-post-preview-title-itself">
                    Реклама в Телеграм
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/chart.svg`}
                    alt="chart"
                  />
                </div>
                <div className="blog-post-preview-text">
                  Мессенджер Telegram стремительно набрала обороты и уже сейчас
                  имеет аудиторию 50 миллионов человек. А это половина России! В
                  2021 были установлены рекордные показатели по объему рекламы в
                  Телеграме и составили около 15 миллиардов рублей. Как же
                  грамотно вести телеграм-канал и использовать его как
                  инструмент продвижения?
                </div>
              </div>
            </Link>
          </Col>
          <Col xs={12} md={3} className="blog-preview-col">
            <Link
              to={`/blog/how-to-telegram-channel`}
              className="no-link-decoration"
            >
              <div className="benefits-card second-blog-post-preview">
                <div className="blog-post-preview-title">
                  <div className="blog-post-preview-title-itself">
                    Как вести канал в Telegram
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/rocket.svg`}
                    alt="rocket"
                  />
                </div>
                <div className="blog-post-preview-text">
                  Telegram-каналы – один из самых популярных инструментов
                  продвижения собственного бренда в настоящее время. Как всем
                  известно – это не просто мессенджер, а мощная площадка для
                  обмена контентом и пользовательского взаимодействия. На
                  сегодняшний день русскоязычный сегмент содержит более 300 000
                  крупных и мелких каналов. Кстати, общая аудитория Telegram
                  составляет уже 50 миллионов человек!
                </div>
              </div>
            </Link>
          </Col>
          <Col xs={12} md={3} className="blog-preview-col">
            <Link
              to={`/blog/group-channel-difference`}
              className="no-link-decoration"
            >
              <div className="benefits-card third-blog-post-preview">
                <div className="blog-post-preview-title">
                  <div className="blog-post-preview-title-itself">
                    Чем отличается канал от группы в Telegram?
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/discussion.svg`}
                    alt="discussion"
                  />
                </div>
                <div className="blog-post-preview-text">
                  На сегодняшний день интернет-общение с аудиторией в
                  мессенджерах набирает все большую популярность. Группы и
                  каналы в сети Телеграм – не только популярные, но еще и самые
                  действенные способы для взаимодействия пользователей и
                  продвижения собственного бренда/продукта. Но как же
                  определить, что именно создавать? В чем их различия,
                  преимущества/недостатки?
                </div>
              </div>
            </Link>
          </Col>
        </Row>
        <Row className="landing-faq-container">
          <Col className="landing-faq-container-title-col">
            <div className="landing-faq-container-title">
              Популярные вопросы
            </div>
            <div className="landing-faq-container-caption">
              Не нашли ответ на свой вопрос?
              <a href="javascript:jivo_api.open();">
                <button>Написать</button>
              </a>
            </div>
          </Col>
        </Row>
        <Row className="landing-faq-questions-container">
          <Col md={6}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Парсинг: что это?</Accordion.Header>
                <Accordion.Body>
                  Парсинг - это процесс сбора участников из чата. Из всей
                  совокупности аудитории группового чата выбираются только те
                  люди, которые подходят под заданные параметры. недавно были в
                  сети.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Можно ли отсеять нежелательных участников группы?
                </Accordion.Header>
                <Accordion.Body>
                  Да, можно. При парсинге все администраторы, модераторы, боты,
                  мертвые и неактивные участники автоматически убираются из
                  списка, если в комментарии к заказу не указано обратно.
                  Удалить отдельных активных участников чата также возможно -
                  достаточно прикрепить их номера или юзернеймы в комментарии к
                  заказу.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  По каким параметрам парсятся участники из групп?
                </Accordion.Header>
                <Accordion.Body>
                  Участники фильтруются по активности и вовлеченности. Парсить
                  можно тех, кто был онлайн час назад, 5 часов назад, за
                  последние сутки/неделю/месяц (сроки указаны в качестве
                  примера). НЕЛЬЗЯ парсить участников по: полу, возрасту,
                  локации, количеству отправленных в чат сообщений.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Можно ли спарсить пользователей из каналов?
                </Accordion.Header>
                <Accordion.Body>
                  К сожалению, парсинг из каналов невозможен. Спарсить аудиторию
                  можно только из группового чата Telegram вашей тематики.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col md={6}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Что такое рассылка?</Accordion.Header>
                <Accordion.Body>
                  Рассылка - это услуга, с помощью которой можно
                  прорекламировать свой канал, чат или бота в Телеграме, а также
                  любой внешний ресурс (сайт, товар, услуга). В личные сообщения
                  вашей целевой аудитории приходит текст со ссылкой на ваш
                  ресурс.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Как осуществляется рассылка?
                </Accordion.Header>
                <Accordion.Body>
                  Путем парсинга собираются активные участники из чатов вашей
                  тематики, после чего каждому из них рассылаются сообщения.
                  Обращаем внимание, что все ссылки скрываются через бота или
                  редирект во избежании бана вашего ресурса или ссылки на
                  ресурс.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Как ссылка “прячется” через бота?
                </Accordion.Header>
                <Accordion.Body>
                  В сообщении пользователя вместо ссылки на ваш ресурс указана
                  ссылка на бота, созданного нашим сервисом. Человек переходит
                  по ссылке и активирует бота, который высылает пользователю
                  ссылку на ваш ресурс. Это защищает ваш ресурс и ссылку на него
                  от нежелательного бана.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Хочу сделать рассылку по моей базе номеров, это возможно?
                </Accordion.Header>
                <Accordion.Body>
                  Да, возможно, если указанные номера зарегистрированы в сети
                  Telegram. Мы сами это проверим совершенно бесплатно.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
