import { AES, MD5 } from "crypto-js";
import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { io } from "socket.io-client";
import Order from "../../Orders/Order/Order";
import { singOnUnreg } from "../../../../Store/Utils/thunkCreators";
import { useDispatch } from "react-redux";
import "./UnauthorizedOrder.css";
import Swal from "sweetalert2";
import axios from "axios";

const UnauthorizedOrder = () => {
  let leadSource = localStorage.getItem("leadSource");
  const [email, setEmail] = useState("");
  const location = useLocation();
  const amount = location.state.formData.priceRu;
  const token = Cookies.get("token");
  const [socketId, setSocketId] = useState("");
  const dispatch = useDispatch();
  const [promoId, setPromoId] = useState("");
  const [successAmount, setSuccessAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [promoResponse, setPromoResponse] = useState("");

  const promoLeadSources = ["moroz"];

  let crypt = AES.encrypt(
    JSON.stringify(location.state.formData),
    "abobavoba"
  ).toString();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    showCloseButton: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const sleep = (delay) => {
    return new Promise(function (resolve) {
      setTimeout(resolve, delay);
    });
  };

  const handlePromo = (e) => {
    const promo = e.target.value;
    const promoLength = promo.length;

    if (promoLength === 6) {
      fetchPromo(promo);
    }
    if (promoLength < 6 && promoResponse !== "") {
      setPromoResponse("");
    }
  };

  const fetchPromo = async (promo) => {
    let message;
    try {
      setLoading(true);
      const res = await axios.get(
        `https://api.pavelandco.com/api/user/promo/?promo=${promo}&id=${socketId}`
      );
      setSuccessAmount(res.data.amount);
      setPromoId(res.data.id);
      message = `Вы успешно применили промокод на ${res.data.amount}%`;
      setPromoResponse(message);
    } catch (err) {
      switch (err.response.status) {
        case 400:
          message = "Возникла ошибка во время применения промокода";
          break;
        case 404:
          message = "Промокод не найден или истек";
          break;
        case 406:
          message = "Вы уже применяли данный промокод";
          break;
        default:
          message = "Возникла ошибка во время применения промокода";
      }
      setSuccessAmount(0);
      setPromoId(0);
      setPromoResponse(message);
    } finally {
      setLoading(false);
      await sleep(10000);
    }
  };

  useEffect(() => {
    if (!token) {
      const socket = io("https://api.pavelandco.com");
      socket.on("connect", () => {
        setSocketId(socket.id);
        // socket.on()
      });
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("message", (message) => {
        dispatch(singOnUnreg(message.user, message.orders, message.token));
        window.location.reload();
      });
      socket.on("be_error", (message) => {
        Toast.fire({
          icon: "error",
          title: `Возникла ошибка`,
          text: "Пожалуйста обратитесь в поддержку, часы работы: 15-24",
        });
      });
    }
  }, [token]);

  return (
    <>
      <Container>
        <Row>
          <Col md={3}>
            <div className="future-order-col">
              <h4 className="mb-2">Информация о будущем заказе</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Order order={location.state.formData} unregistered={true} />
          <Col>
            <div>
              <form
                className="unreg-form"
                method="get"
                action="https://pay.freekassa.ru/"
                target="_blank"
              >
                <input type="hidden" name="m" defaultValue="36638" />
                <input type="hidden" name="o" value={email} />
                <input
                  type="hidden"
                  name="s"
                  value={MD5(
                    `36638:${Math.round(
                      amount * (1 - successAmount / 100) || 0
                    )}:]7}tV&VOK59vohP:RUB:${email}`
                  ).toString()}
                />
                <input type="hidden" name="currency" defaultValue="RUB" />
                <input type="hidden" name="lang" defaultValue="ru" />
                <input type="hidden" name="us_lang" defaultValue="ru" />
                <input
                  type="hidden"
                  name="us_amount"
                  value={Math.round(amount * (1 - successAmount / 100) || 0)}
                />
                <input type="hidden" name="us_id" defaultValue="unreg" />
                <input type="hidden" name="us_lead" defaultValue={leadSource} />
                <input type="hidden" name="us_promo" value={promoId} />
                <input
                  type="hidden"
                  name="us_order"
                  defaultValue={crypt.replaceAll("/", "_").replaceAll("+", ".")}
                />
                <input
                  type="hidden"
                  name="us_socketid"
                  defaultValue={socketId}
                />

                <input
                  value={Math.round(amount * (1 - successAmount / 100) || 0)}
                  type="hidden"
                  name="oa"
                />
                {/* <div className="unreg-email-col"> */}
                <label for="us_email" className="us_email_label">
                  Введите ваш email
                </label>
                <input
                  required
                  name="us_email"
                  className="input-type-1 unreg-file-field"
                  type="email"
                  placeholder="example@mail.com"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                {promoLeadSources.includes(leadSource) && (
                  <>
                    <label htmlFor="promo" className="us_email_label">
                      Введите промокод:
                    </label>
                    <input
                      className="input-type-1 unreg-file-field"
                      name="promo"
                      id="promo"
                      onChange={handlePromo}
                      placeholder="******"
                      maxLength="6"
                    />
                    <div
                      className={`balance-promo-field ${
                        successAmount === 0 && "error-promo"
                      }`}
                    >
                      {promoResponse}
                      {loading && (
                        <Spinner animation="border" variant="light" />
                      )}
                    </div>
                  </>
                )}

                <button
                  className="button-type-1 me-0 unreg-file-field"
                  type="submit"
                >
                  Оплатить {Math.round(amount * (1 - successAmount / 100) || 0)}{" "}
                  руб
                </button>
                <div className="you-chose-services unreg-hint">
                  После оплаты Вы будете перенаправлены в личный кабинет для
                  отслеживания заказа.
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UnauthorizedOrder;
