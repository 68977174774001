import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { calculatePriceViews } from "../../CalculatePrice";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { processOrder } from "../../../../../Store/Utils/thunkCreators";

const Views = () => {
  const token = Cookies.get("token");

  const navigate = useNavigate();

  const [amount, setAmount] = useState(2500);
  const [loading, setLoading] = useState(false);
  const [days, setDays] = useState(30);
  const [min, setMin] = useState(800);
  const [max, setMax] = useState(1000);
  const [langOption, setLangOption] = useState(1);

  let formData = { tg_views: {} };

  const price = calculatePriceViews(amount, days);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    showCloseButton: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const firstHint = {
    title: "Количество",
    text: "Выберите количество просмотров и дней",
  };
  const secondHint = {
    title: "Оставьте ссылку",
    text: `Оставьте ссылку на канал.`,
  };
  const thirdHint = {
    title: "Количество просмотров в час",
    text: `Выберите количество просмотров в час, а также разброс просмотров.`,
  };

  const fourthHint = {
    title: "Язык",
    text: `Выберите один из предложенных вариантов языка.`,
  };

  const fifthHint = {
    title: "Оставьте комментарий",
    text: `Если вы хотите ещё что-нибудь добавить, то вы можете написать комментарий к заказу.`,
  };

  const priceHintContent = {
    title: "Цена",
    html: ` <div class="container-fluid pricing-hint-container">
        <div class="row">
          <div class="col-11 ${
            amount < 5001 && "price-gap-selected"
          }">1-5000 Просмотров на каждый пост - 167 руб. за день </div>
          <div class="col-1">${
            amount < 5001
              ? ` <svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <div class="row">
          <div class="col-11 ${
            amount >= 5001 && amount < 10001 && "price-gap-selected"
          }">5001-10000 Просмотров на каждый пост - 300 руб. за день</div>
          <div class="col">${
            amount >= 5001 && amount < 10001
              ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <div class="row">
          <div class="col-11 ${
            amount >= 10001 && amount < 15001 && "price-gap-selected"
          }">10001-15000 Просмотров на каждый пост - 320 руб. за день</div>
          <div class="col">${
            amount >= 10001 && amount < 15001
              ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <div class="row">
          <div class="col-11 ${
            amount >= 15001 && amount < 20001 && "price-gap-selected"
          }">15001-20000 Просмотров на каждый пост - 340 руб. за день</div>
          <div class="col">${
            amount >= 15001 && amount < 20001
              ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <div class="row">
          <div class="col-11 ${
            amount >= 20001 && amount < 25001 && "price-gap-selected"
          }">20001-25000 Просмотров на каждый пост - 360 руб. за день</div>
          <div class="col">${
            amount >= 20001 && amount < 25001
              ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <div class="row">
          <div class="col-11 ${
            amount >= 25001 && amount < 30001 && "price-gap-selected"
          }">25001-30000 Просмотров на каждый пост - 380 руб. за день</div>
          <div class="col">${
            amount >= 25001 && amount < 30001
              ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <div class="row">
          <div class="col-11 ${
            amount >= 30001 && amount < 35001 && "price-gap-selected"
          }">30001-35000 Просмотров на каждый пост - 400 руб. за день</div>
          <div class="col">${
            amount >= 30001 && amount < 35001
              ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <div class="row">
          <div class="col-11 ${
            amount >= 35001 && amount < 40001 && "price-gap-selected"
          }">35001-40000 Просмотров на каждый пост - 420 руб. за день</div>
          <div class="col">${
            amount >= 35001 && amount < 40001
              ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <div class="row">
          <div class="col-11 ${
            amount >= 40001 && amount < 45001 && "price-gap-selected"
          }">40001-45000 Просмотров на каждый пост - 440 руб. за день</div>
          <div class="col">${
            amount >= 40001 && amount < 45001
              ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <div class="row">
          <div class="col-11 ${
            amount >= 45001 && "price-gap-selected"
          }">45001-50000 Просмотров на каждый пост - 460 руб. за день</div>
          <div class="col">${
            amount >= 45001
              ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>

        <hr />
        <div class="row">
        <div class="col-11 ${
          days >= 30 && "price-gap-selected"
        }">Дней > 29 - 50% Скидка!</div>
        <div class="col">${
          days >= 30
            ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
            : ""
        }</div>
      </div>
      
    </div>
      </div>`,
  };

  const handleHint = (content) => {
    Swal.fire({
      title: content.title,
      text: content.text,
      html: content.html,
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        popup: "pop-up-type-1 hint-pop-up",
        title: "pop-up-type-1-title",
        closeButton: "pop-up-type-1-close",
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    formData.amount = amount;
    formData.comment = e.target.comment.value;
    formData.type = "tg_views";
    formData.status = "Queued up";
    formData.dateOrdered = new Date();
    formData.priceRu = parseFloat(price);
    formData.priceEn = 0;
    formData.tg_views.channel = e.target.link.value;
    formData.tg_views.startTime = formData.dateOrdered;
    formData.tg_views.daysCount = days;
    formData.tg_views.minViewsPerHour = min;
    formData.tg_views.maxViewsPerHour = max;
    formData.tg_views.country = langOption;

    axios.defaults.headers.post["Authorization"] = `jwt ${token}`;
    setLoading(true);
    if (!token) {
      setLoading(false);
      return navigate("/service/pay", {
        state: {
          formData,
        },
      });
    }
    try {
      const { data } = await axios.post(
        `https://api.pavelandco.com/api/user/order/ru`,
        formData
      );
      Toast.fire({
        icon: "success",
        title: "Заказ успешно создан",
      });
      dispatch(processOrder(data));
      return navigate("/service/orders");
    } catch (error) {
      if (error.request.status === 406) {
        Toast.fire({
          icon: "error",
          title: `Недостаточно средств на балансе  (${
            price - user.balanceRu
          } р)`,
        });
      } else if (error.request.status === 401) {
        Toast.fire({
          icon: "error",
          title: `Отсутствует регистрация`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: `Ошибка - ${error}`,
        });
      }
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/1.svg`} alt="one" />
            <div className="order-hint-caption">
              Выберите количество просмотров и дней
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-col">
              <div className="order-input-title">
                <img
                  src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                  alt={"question"}
                  onClick={() => handleHint(firstHint)}
                  className="hide-on-desktop"
                />
                Количество
              </div>
              <div className="order-input-title-hint hide-on-mobile">
                Просмотров
              </div>
              <div className="order-input-range-field double-range-input mb-3">
                <input
                  type="range"
                  min={1}
                  max={50000}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                  step={amount < 5000 ? 500 : 1000}
                />
                <div className="range-input-numer-field">
                  <input
                    type="number"
                    min={1}
                    max={50000}
                    value={amount}
                    required
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <div className="order-range-input-caption">Просмотров</div>
                </div>
              </div>
              <div className="order-input-title-hint hide-on-mobile">Дней</div>
              <div className="order-input-range-field double-range-input">
                <input
                  type="range"
                  min={1}
                  max={365}
                  value={days}
                  onChange={(e) => setDays(e.target.value)}
                  required
                  step={1}
                />
                <div className="range-input-numer-field">
                  <input
                    type="number"
                    min={1}
                    max={365}
                    value={days}
                    required
                    onChange={(e) => setDays(e.target.value)}
                  />
                  <div className="order-range-input-caption">Дней</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/2.svg`} alt="two" />
            <div className="order-hint-caption">Оставьте ссылку на канал.</div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-col">
              <div className="order-input-title">
                <img
                  src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                  alt={"question"}
                  onClick={() => handleHint(secondHint)}
                  className="hide-on-desktop"
                />
                Оставьте ссылку
              </div>
            </div>
            <input
              required
              type="text"
              name="link"
              className={`input-type-2`}
              placeholder={"Ссылка..."}
            />
          </Col>
        </Row>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/3.svg`} alt="three" />
            <div className="order-hint-caption">
              Выберите количество просмотров в час, а также разброс просмотров.
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-col">
              <div className="order-input-title">
                <img
                  src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                  alt={"question"}
                  onClick={() => handleHint(thirdHint)}
                  className="hide-on-desktop"
                />
                Количество просмотров в час
              </div>
              <div className="order-input-title-hint hide-on-mobile">
                Минимально
              </div>
              <div className="order-input-range-field double-range-input mb-3">
                <input
                  type="range"
                  min={1}
                  max={max}
                  value={min}
                  onChange={(e) => setMin(e.target.value)}
                  required
                  step={1}
                />
                <div className="range-input-numer-field">
                  <input
                    type="number"
                    min={1}
                    max={max}
                    value={min}
                    required
                    onChange={(e) => setMin(e.target.value)}
                  />
                  <div className="order-range-input-caption">Просмотров</div>
                </div>
              </div>
              <div className="order-input-title-hint hide-on-mobile">
                Максимально
              </div>
              <div className="order-input-range-field double-range-input">
                <input
                  type="range"
                  min={min}
                  max={1800}
                  value={max}
                  onChange={(e) => setMax(e.target.value)}
                  required
                  step={1}
                />
                <div className="range-input-numer-field">
                  <input
                    type="number"
                    min={min}
                    max={1800}
                    value={max}
                    required
                    onChange={(e) => setMax(e.target.value)}
                  />
                  <div className="order-range-input-caption">Просмотров</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/4.svg`} alt="four" />
            <div className="order-hint-caption">
              Выберите один из предложенных вариантов языка.
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-col">
              <div className="order-input-title">
                <img
                  src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                  alt={"question"}
                  onClick={() => handleHint(fourthHint)}
                  className="hide-on-desktop"
                />
                Язык
              </div>
              <div className="order-extra-service-input-col views-lang-col">
                <div className="views-lang-single-option">
                  <div className="order-extra-service-caption">Все языки</div>
                  <div className="flipswitch">
                    <input
                      type="checkbox"
                      name="all"
                      className="flipswitch-cb"
                      id="fs1"
                      checked={langOption === 0}
                      onChange={() => setLangOption(0)}
                    />
                    <label className="flipswitch-label" htmlFor="fs1">
                      <div className="flipswitch-inner"></div>
                      <div className="flipswitch-switch"></div>
                    </label>
                  </div>
                </div>
                <div className="views-lang-single-option">
                  <div className="order-extra-service-caption">Русский</div>
                  <div className="flipswitch">
                    <input
                      type="checkbox"
                      name="ru"
                      className="flipswitch-cb"
                      id="fs2"
                      checked={langOption === 1}
                      onChange={() => setLangOption(1)}
                    />
                    <label className="flipswitch-label" htmlFor="fs2">
                      <div className="flipswitch-inner"></div>
                      <div className="flipswitch-switch"></div>
                    </label>
                  </div>
                </div>
                <div className="views-lang-single-option">
                  <div className="order-extra-service-caption">
                    Русский (СНГ)
                  </div>
                  <div className="flipswitch">
                    <input
                      type="checkbox"
                      name="cis"
                      className="flipswitch-cb"
                      id="fs3"
                      checked={langOption === 16}
                      onChange={() => setLangOption(16)}
                    />
                    <label className="flipswitch-label" htmlFor="fs3">
                      <div className="flipswitch-inner"></div>
                      <div className="flipswitch-switch"></div>
                    </label>
                  </div>
                </div>
                <div className="views-lang-single-option">
                  <div className="order-extra-service-caption">Английский</div>
                  <div className="flipswitch">
                    <input
                      type="checkbox"
                      name="eng"
                      className="flipswitch-cb"
                      id="fs4"
                      checked={langOption === 2}
                      onChange={() => setLangOption(2)}
                    />
                    <label className="flipswitch-label" htmlFor="fs4">
                      <div className="flipswitch-inner"></div>
                      <div className="flipswitch-switch"></div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/5.svg`} alt="five" />
            <div className="order-hint-caption">
              Если вы хотите ещё что-нибудь добавить, то вы можете написать
              комментарий к заказу.
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-col">
              <div className="order-input-title">
                <img
                  src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                  alt={"question"}
                  onClick={() => handleHint(fifthHint)}
                  className="hide-on-desktop"
                />
                Оставьте комментарий
              </div>
              <input
                type="text"
                name="comment"
                className={`input-type-2`}
                placeholder="Ваше сообщение..."
              />
            </div>
          </Col>
        </Row>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/6.svg`} alt="six" />
            <div className="order-hint-caption">
              Создавайте заказ и наблюдайте за его статусом в личном кабинете :)
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-row order-create-order-row">
              <button
                className={`button-type-1 ${
                  loading ? "axios-loading" : undefined
                }`}
                type="submit"
                disabled={loading}
              >
                Сделать заказ
              </button>
              <div className="order-input-title">Итого: </div>
              <div className="order-extra-service-input-col">
                <div className="order-extra-service-caption">
                  {isNaN(price) ? "Ошибка" : price}
                  {" руб."}
                  <img
                    src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                    alt={"question"}
                    onClick={() => handleHint(priceHintContent)}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default Views;
