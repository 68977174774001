import { text as orderTextImport } from "../../../../assets/texts/orderForm.js";
import { text as FAQtextImport } from "../../../../assets/texts/faq.js";
import OrderFAQ from "./OrderFAQ.js";
import "./SeoOrderForm.css";
import WhyUs from "./WhyUs.js";

const SeoOrderForm = (props) => {
  const FAQtext = FAQtextImport.filter((textEntity) => {
    return textEntity.tag === props.orderType;
  })[0].content;

  return (
    <>
      <OrderFAQ FAQtext={FAQtext} />
      <WhyUs />
    </>
  );
};

export default SeoOrderForm;
