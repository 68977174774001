import { connect } from "react-redux";
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Cookies from "js-cookie";
import ServiceOptionBar from "../ServiceOptionBar/ServiceOptionBar";
import "./Service.css";
import "./RangeInput.css";
import "./OrderForm.css";
import "./SwitchInput.css";
import "./RadioInput.css";
import ContactBanner from "./ContactBanner/ContactBanner";
import { useEffect } from "react";
import SeoOrderForm from "./SeoOrderForm/SeoOrderForm";

const Service = (props) => {
  const token = Cookies.get("token");

  const sampleLocation = useLocation();
  let title;
  let orderType;
  if (sampleLocation.pathname.includes("telegram/message")) {
    title = "Рассылку в личные сообщения";
    orderType = "tg_spam";
  } else if (sampleLocation.pathname.includes("telegram/inviting")) {
    title = "Инвайтинг";
    orderType = "tg_inviting";
  } else if (sampleLocation.pathname.includes("telegram/group")) {
    title = "Рассылку по чатам";
    orderType = "tg_groupSpam";
  } else if (sampleLocation.pathname.includes("telegram/bots")) {
    title = "Боты";
    orderType = "tg_bots";
  } else if (sampleLocation.pathname.includes("telegram/views")) {
    title = "Просмотры";
    orderType = "tg_views";
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<Navigate to="telegram/message" replace={true} />}
        />
      </Routes>
      <Container fluid className="service-container">
        <Row className="service-container-main-row">
          <Col>
            <ServiceOptionBar />
          </Col>
          <Col lg={10} xs={12} className="margin-rule-for-mobile">
            <Container fluid className="service-content-container">
              <Row className="service-content-link-row">
                <Col
                  className={`service-content-link ${
                    token ? "only-one-right-arrow" : undefined
                  } ${
                    sampleLocation.pathname.includes("telegram") ||
                    sampleLocation.pathname.includes("tiktok") ||
                    sampleLocation.pathname.includes("pay")
                      ? "active-content-link"
                      : "hide-on-mobile"
                  }`}
                  lg={token ? 4 : 12}
                  sm={12}
                >
                  <Link
                    to={
                      token
                        ? "/service/telegram/message"
                        : sampleLocation.pathname
                    }
                    className="router-link-service-caption "
                  >
                    <div>
                      <span>
                        {sampleLocation.pathname.includes("pay")
                          ? "Подтверждение заказа"
                          : "Расчет стоимости"}
                      </span>
                    </div>
                  </Link>
                  <Link
                    to="/service/balance"
                    className={`hide-on-desktop hide-on-medium ${
                      !token ? "hide-on-mobile" : undefined
                    } router-link-service-image`}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/arrowRight.svg`}
                      alt="arrow-right"
                    />
                  </Link>
                </Col>
                {token && (
                  <>
                    <Col
                      className={`service-content-link both-service-arrows ${
                        sampleLocation.pathname.includes("balance")
                          ? "active-content-link"
                          : "hide-on-mobile"
                      }`}
                    >
                      <Link
                        to="/service/telegram/message"
                        className={`hide-on-desktop hide-on-medium 
                        ${
                          !token ? "hide-on-mobile" : undefined
                        } router-link-service-image`}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/images/arrowLeft.svg`}
                          alt="arrow-left"
                        />
                      </Link>
                      <Link to="/service/balance">
                        <div>
                          <span>Пополнить баланс</span>
                        </div>
                      </Link>
                      <Link
                        to="/service/orders"
                        className={`hide-on-desktop hide-on-medium ${
                          !token ? "hide-on-mobile" : undefined
                        } router-link-service-image`}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/images/arrowRight.svg`}
                          alt="arrow-right"
                        />
                      </Link>
                    </Col>
                    <Col
                      className={`service-content-link both-service-arrows ${
                        sampleLocation.pathname.includes("orders")
                          ? "active-content-link"
                          : "hide-on-mobile"
                      }`}
                    >
                      <Link
                        to="/service/balance"
                        className={`hide-on-desktop hide-on-medium ${
                          !token ? "hide-on-mobile" : undefined
                        } router-link-service-image`}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/images/arrowLeft.svg`}
                          alt="arrow-left"
                        />
                      </Link>
                      <Link to="/service/orders">
                        <div>
                          <span>История заказов</span>
                        </div>
                      </Link>
                      <Link
                        to="/service/settings"
                        className={`hide-on-desktop hide-on-medium ${
                          !token ? "hide-on-mobile" : undefined
                        } router-link-service-image`}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/images/arrowRight.svg`}
                          alt="arrow-right"
                        />
                      </Link>
                    </Col>
                    <Col
                      className={`service-content-link only-one-left-arrow ${
                        sampleLocation.pathname.includes("settings")
                          ? "active-content-link"
                          : "hide-on-mobile"
                      }`}
                    >
                      <Link
                        to="/service/orders"
                        className={`hide-on-desktop hide-on-medium ${
                          !token ? "hide-on-mobile" : undefined
                        } router-link-service-image`}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/images/arrowLeft.svg`}
                          alt="arrow-left"
                        />
                      </Link>
                      <Link
                        to="/service/settings"
                        className="router-link-service-caption"
                      >
                        <div>
                          <span>Настройки</span>
                        </div>
                      </Link>
                    </Col>
                  </>
                )}
              </Row>
              <Row className="service-content-itself-row">
                <Outlet />
              </Row>
            </Container>
            <ContactBanner />
            {orderType && <SeoOrderForm orderType={orderType} />}
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Service);
