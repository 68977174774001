import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Tree.css";
import { useState } from "react";

const Tree = () => {
  const [changeSceneAction, setChangeSceneAction] = useState(false);
  const [phase, setPhase] = useState(1);
  const navigate = useNavigate();

  const changeScene = (phaseTarget) => {
    setChangeSceneAction(true);

    setTimeout(() => {
      setPhase(phaseTarget);
    }, 300);

    setTimeout(() => {
      setChangeSceneAction(false);
    }, 500);
  };

  return (
    <>
      <Container className="tree-container">
        <Row>
          <Col>
            <div className="tree-steps">
              <div>1 Шаг</div>
              <div className={`${phase === 2.2 ? "deleted-step" : undefined}`}>
                2 Шаг
              </div>
              <div>{phase === 2.2 ? 2 : 3} Шаг</div>
            </div>
            <div className="progress-bar-container">
              <div
                className={`tree-progress-bar ${
                  phase === 2.1 ? "tree-progress-bar-66" : undefined
                } ${phase > 2.1 ? "tree-progress-bar-100" : undefined}`}
              ></div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className={`${
                changeSceneAction ? "content-change-in-action" : undefined
              }`}
            >
              {phase === 1 && (
                <div className={`tree-start-title`}>Что будем продвигать?</div>
              )}
              {phase > 2 && phase < 3 && (
                <div className="tree-folowing-questions">
                  <div className="tree-folowing-questions-title">
                    Какая у Вас цель?
                  </div>
                  <div className="tree-folowing-questions-body">
                    {phase === 2.1 && "Вы выбрали Telegram."}
                    {phase === 2.2 && "Вы выбрали TikTok."}
                    <button onClick={() => changeScene(1)}>
                      Вернуться на предыдущий шаг.
                    </button>
                  </div>
                </div>
              )}
              {phase > 3 && (
                <div className="tree-folowing-questions">
                  <div className="tree-folowing-questions-title">
                    Выберите услугу
                  </div>

                  <div className="tree-folowing-questions-body">
                    {phase === 3.1 && "Вы выбрали Канал."}
                    {phase === 3.2 && "Вы выбрали Групповой чат."}
                    {phase === 3.3 && "Вы выбрали Бот."}
                    {phase === 3.4 && "Вы выбрали Аĸĸаунт в тг."}
                    {phase === 3.5 && "Вы выбрали Сайт."}
                    <button onClick={() => changeScene(2.1)}>
                      Вернуться на предыдущий шаг.
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className={`tree-changing-content ${
                changeSceneAction ? "content-change-in-action" : undefined
              }`}
            >
              {phase === 1 && (
                <div className="tree-first-step-buttons">
                  <button
                    className="tree-button tg-tree"
                    onClick={() => changeScene(2.1)}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/telegram.svg`}
                      alt="telegram"
                    />
                    Telegram
                  </button>
                  <button
                    className="tree-button tt-tree"
                    onClick={() => changeScene(2.2)}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/tiktok.svg`}
                      alt="tiktok"
                    />{" "}
                    TikTok
                  </button>
                </div>
              )}
              {phase === 2.1 && (
                <div className="tree-phase-buttons-container">
                  <button
                    className="tree-phase-button"
                    onClick={() => changeScene(3.1)}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/link.svg`}
                      alt="Канал"
                    />
                    Канал
                  </button>
                  <button
                    className="tree-phase-button"
                    onClick={() => changeScene(3.2)}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/groupMsg.svg`}
                      alt="Групповой чат"
                    />
                    Групповой чат
                  </button>
                  <button
                    className="tree-phase-button"
                    onClick={() => changeScene(3.3)}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/bots.svg`}
                      alt="Бот"
                    />
                    Бот
                  </button>
                  <button
                    className="tree-phase-button"
                    onClick={() => changeScene(3.4)}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/account.svg`}
                      alt="Аĸĸаунт в тг"
                    />
                    Аĸĸаунт в тг
                  </button>
                  <button
                    className="tree-phase-button"
                    onClick={() => changeScene(3.5)}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/planet.svg`}
                      alt="Сайт"
                    />
                    Сайт
                  </button>
                </div>
              )}
              {phase === 2.2 && (
                <div className="tree-phase-buttons-container">
                  <button
                    className="tree-phase-button"
                    onClick={() =>
                      navigate("/service/tiktok/likes", {
                        replace: true,
                      })
                    }
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/inviting.svg`}
                      alt="Лайки"
                    />
                    Лайки
                  </button>

                  <button
                    className="tree-phase-button"
                    onClick={() =>
                      navigate("/service/tiktok/views", { replace: true })
                    }
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/views.svg`}
                      alt="Просмотры"
                    />
                    Просмотры
                  </button>

                  <button
                    className="tree-phase-button"
                    onClick={() =>
                      navigate("/service/tiktok/subs", { replace: true })
                    }
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/inviting.svg`}
                      alt="Подписчики"
                    />
                    Подписчики
                  </button>

                  <button
                    className="tree-phase-button"
                    onClick={() =>
                      navigate("/service/tiktok/share", { replace: true })
                    }
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/share.svg`}
                      alt="Репосты"
                    />
                    Репосты
                  </button>
                </div>
              )}
              {phase > 3 && (
                <div className="tree-phase-buttons-container">
                  {phase === 3.2 && (
                    <button
                      className="tree-phase-button"
                      onClick={() =>
                        navigate("/service/telegram/inviting", {
                          replace: true,
                        })
                      }
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/images/inviting.svg`}
                        alt="Инвайтинг"
                      />
                      Инвайтинг
                    </button>
                  )}
                  {phase === 3.1 && (
                    <button
                      className="tree-phase-button"
                      onClick={() =>
                        navigate("/service/telegram/views", { replace: true })
                      }
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/images/views.svg`}
                        alt="Наĸрутĸа Просмотров"
                      />
                      Наĸрутĸа Просмотров
                    </button>
                  )}
                  {(phase === 3.1 || phase === 3.2 || phase === 3.3) && (
                    <button
                      className="tree-phase-button"
                      onClick={() =>
                        navigate("/service/telegram/bots", { replace: true })
                      }
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/images/bots.svg`}
                        alt="Наĸрутĸа ботов"
                      />
                      Наĸрутĸа ботов
                    </button>
                  )}
                  {/* {phase > 3 && (
                    <button
                      className="tree-phase-button"
                      onClick={() =>
                        navigate("/service/telegram/group", { replace: true })
                      }
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/images/groupMsg.svg`}
                        alt="Рассылĸа по групповым чатам"
                      />
                      Рассылĸа по групповым чатам
                    </button>
                  )} */}
                  {phase > 3 && (
                    <button
                      className="tree-phase-button"
                      onClick={() =>
                        navigate("/service/telegram/message", { replace: true })
                      }
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/images/msg.svg`}
                        alt="Рассылĸа в личные сообщения"
                      />
                      Рассылĸа в личные сообщения
                    </button>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Tree;
