import { addNewOrderToStore } from "./Utils/reducerFunctions";

// ACTIONS

const GET_ORDERS = "GET_ORDERS";
const ADD_ORDER = "ADD_ORDER";

// ACTION CREATORS

export const gotOrders = (orders) => {
  return {
    type: GET_ORDERS,
    orders,
  };
};

export const addOrder = (order) => {
  return {
    type: ADD_ORDER,
    payload: { order },
  };
};

// REDUCER

const reducer = (state = [], action) => {
  switch (action.type) {
    case GET_ORDERS:
      return action.orders.reverse();
    case ADD_ORDER:
      return addNewOrderToStore(state, action.payload.order);

    default:
      return state;
  }
};

export default reducer;
