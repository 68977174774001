import { useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Swal from "sweetalert2";
import { calculatePriceInviting } from "../../CalculatePrice";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { processOrder } from "../../../../../Store/Utils/thunkCreators";

const Inviting = () => {
  const token = Cookies.get("token");

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(1000);
  const [baseFile, setBaseFile] = useState([]);
  const baseFileSelected = Object.values(baseFile).length > 0;

  const [findAudience, setFindAudience] = useState(false);

  let formData = { tg_inviting: {} };

  const price = calculatePriceInviting(amount, findAudience);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    showCloseButton: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const firstHint = {
    title: "Количество",
    text: "Выберите количество приглашений",
  };
  const secondHint = {
    title: "Оставьте ссылку",
    text: `Оставьте ссылку на групповой чат.`,
  };
  const thirdHint = {
    title: "База получателей",
    text: `Введите список групповых чатов для сбора аудитории или мы можем сделать это за вас. Добавьте документ, если список уже есть. Выберите количество дней или часов.`,
  };
  const fourthHint = {
    title: "Оставьте комментарий",
    text: `Если вы хотите ещё что-нибудь добавить, то вы можете написать комментарий к заказу.`,
  };

  const baseHintContent = {
    title: "У меня нет чатов для парсинга, что делать?",
    text: "Если у вас нет чатов или не знаете, как их искать, чаты сможем подобрать мы за дополнительную плату. Перед выполнением услуги чаты будут отправляться вам на согласование.",
  };

  const priceHintContent = {
    title: "Цена",
    html: ` <div class="container-fluid pricing-hint-container">
        <div class="row">
          <div class="col-11 ${
            amount < 1001 && "price-gap-selected"
          }">500-1000 Инвайтов- 2.5 руб. за инвайт </div>
          <div class="col-1">${
            amount < 1001
              ? ` <svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <div class="row">
          <div class="col-11 ${
            amount >= 1001 && amount < 5001 && "price-gap-selected"
          }">1001-5000 Инвайтов - 2руб. за инвайт</div>
          <div class="col">${
            amount >= 1001 && amount < 5001
              ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <div class="row">
          <div class="col-11 ${
            amount >= 5001 && amount < 10001 && "price-gap-selected"
          }">5001-10000 Инвайтов - 1.8 руб. за инвайт </div>
          <div class="col">${
            amount >= 5001 && amount < 10001
              ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <div class="row">
          <div class="col-11 ${
            amount > 10000 && "price-gap-selected"
          }">10000+ Инвайтов - 1.6 руб. за инвайт</div>
          <div class="col">${
            amount > 10000
              ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <hr />
      <div class="row">
      <div class="col-11 ${
        findAudience && "price-gap-selected"
      }">Найти Аудиторию за меня: +0.5 руб. за инвайт</div>
      <div class="col">${
        findAudience
          ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
          : ""
      }</div>
    </div>
      </div>`,
  };

  const handleHint = (content) => {
    Swal.fire({
      title: content.title,
      text: content.text,
      html: content.html,
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        popup: "pop-up-type-1 hint-pop-up",
        title: "pop-up-type-1-title",
        closeButton: "pop-up-type-1-close",
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.amount = amount;
    formData.tg_inviting.groupTo = e.target.link.value;
    formData.findAudience = findAudience;
    formData.comment = e.target.comment.value;
    formData.type = "tg_inviting";
    formData.status = "Queued up";
    formData.dateOrdered = new Date();
    formData.priceRu = parseFloat(price);
    formData.priceEn = 0;

    const formFile = new FormData();

    if (!findAudience && baseFileSelected) {
      for (let file of baseFile) {
        formFile.append("usersListFrom", file);
      }
      formData.parseActiveHours = 0;
      formData.parseActiveDays = 0;
    } else if (!findAudience && !baseFileSelected) {
      formData.tg_inviting.groupsFrom = [e.target.groups.value.trim()];
      formData.parseActiveHours =
        e.target.group.value === "hours" ? parseInt(e.target.parse.value) : 0;
      formData.parseActiveDays =
        e.target.group.value === "days" ? parseInt(e.target.parse.value) : 0;
    } else {
      formData.tg_inviting.groupsFrom = [];
    }

    setLoading(true);
    formFile.append("fields", JSON.stringify(formData));
    axios.defaults.headers.post["Authorization"] = `jwt ${token}`;
    if (!token) {
      setLoading(false);
      return navigate("/service/pay", {
        state: {
          formData,
        },
      });
    }
    try {
      const { data } = await axios.post(
        `https://api.pavelandco.com/api/user/order/tginviting/ru`,
        formFile
      );
      Toast.fire({
        icon: "success",
        title: "Заказ успешно создан",
      });
      dispatch(processOrder(data));
      return navigate("/service/orders");
    } catch (error) {
      if (error.request.status === 406) {
        Toast.fire({
          icon: "error",
          title: `Недостаточно средств на балансе  (${
            price - user.balanceRu
          } р)`,
        });
      } else if (error.request.status === 401) {
        Toast.fire({
          icon: "error",
          title: `Отсутствует регистрация`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: `Ошибка - ${error}`,
        });
      }
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/1.svg`} alt="one" />
            <div className="order-hint-caption">
              Выберите количество приглашений
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-col">
              <div className="order-input-title">
                <img
                  src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                  alt={"question"}
                  onClick={() => handleHint(firstHint)}
                  className="hide-on-desktop"
                />
                Количество
              </div>
              <div className="order-input-range-field">
                <input
                  type="range"
                  min={500}
                  max={50000}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                  step={amount < 5000 ? 500 : 1000}
                />
                <div className="range-input-numer-field">
                  <input
                    type="number"
                    min={500}
                    value={amount}
                    required
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <div className="order-range-input-caption">Приглашений</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/2.svg`} alt="two" />
            <div className="order-hint-caption">
              Оставьте ссылку на групповой чат.
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-col">
              <div className="order-input-title">
                <img
                  src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                  alt={"question"}
                  onClick={() => handleHint(secondHint)}
                  className="hide-on-desktop"
                />
                Оставьте ссылку
              </div>
            </div>
            <input
              required
              type="text"
              name="link"
              className={`input-type-2`}
              placeholder={"Ссылка..."}
            />
          </Col>
        </Row>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/3.svg`} alt="three" />
            <div className="order-hint-caption">
              Введите список групповых чатов для сбора аудитории или мы можем
              сделать это за вас. Добавьте документ, если список уже есть.
              Выберите количество дней или часов.
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-col">
              <div className="order-input-title">
                <img
                  src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                  alt={"question"}
                  onClick={() => handleHint(thirdHint)}
                  className="hide-on-desktop"
                />
                База получателей
              </div>
            </div>
            <input
              required={!findAudience}
              type="text"
              name="groups"
              className={`input-type-2 ${findAudience && "disabled-input"} ${
                baseFileSelected && "file-selected-input"
              }`}
              placeholder={
                baseFileSelected
                  ? "Уже добавлен файл"
                  : "Групповые чаты для сбора аудитории"
              }
              disabled={baseFileSelected}
            />
            <div className="order-parse-row">
              <div className="order-range-input-caption">
                Собрать активных участников за
              </div>
              <input
                name="parse"
                type="number"
                min="1"
                max="60"
                defaultValue="5"
                required={!findAudience}
                disabled={findAudience || baseFileSelected}
              />
              <div className="order-radio">
                <input
                  id="days"
                  name="group"
                  type="radio"
                  value="days"
                  defaultChecked
                  disabled={findAudience || baseFileSelected}
                />
                <label htmlFor="days" className="order-radio-label">
                  дней
                </label>
              </div>
              <div className="order-radio">
                <input
                  id="hours"
                  name="group"
                  value="hours"
                  type="radio"
                  disabled={findAudience || baseFileSelected}
                />
                <label htmlFor="hours" className="order-radio-label">
                  часов
                </label>
              </div>
            </div>
            <div className="order-file-input-row">
              {token && (
                <OverlayTrigger
                  trigger={["click", "hover"]}
                  placement="bottom"
                  delay={{ show: 150, hide: 150 }}
                  overlay={
                    token ? (
                      <></>
                    ) : (
                      <Tooltip id="button-tooltip-2">
                        Загрузка файлов доступна зарегистрированным
                        пользователям
                      </Tooltip>
                    )
                  }
                >
                  <div
                    className={`order-file-input-col ${
                      !token || findAudience ? "disabled-file" : undefined
                    }`}
                  >
                    <div
                      className={`order-file-input ${
                        baseFileSelected
                          ? "file-selected-input-selected"
                          : undefined
                      }`}
                    >
                      <label
                        htmlFor="file-input-field-base"
                        className={
                          baseFileSelected ? "file-selected" : undefined
                        }
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/images/${
                            baseFileSelected ? "cross" : "plus"
                          }.svg`}
                          alt="plus"
                        />
                        {baseFileSelected
                          ? "Очистить файлы"
                          : "Загрузить документ"}
                      </label>
                      <input
                        disabled={!token || findAudience}
                        type="file"
                        id="file-input-field-base"
                        name="groupsFile"
                        title=""
                        onChange={(event) => setBaseFile(event.target.files)}
                        accept=".txt,.docx,.doc,.pdf,.pages,.xml,.xlsx,.xls,.xlsb"
                        onClick={(e) => {
                          if (baseFileSelected) {
                            e.preventDefault();
                            e.target.value = null;
                            setBaseFile({});
                          }
                        }}
                      />
                    </div>
                    <div className="file-input-field-selected-file">
                      {Object.values(baseFile).map((file) => file.name)}
                    </div>
                  </div>
                </OverlayTrigger>
              )}
              <div className="order-extra-service-input-col">
                <div className="order-extra-service-without-switch">
                  <div className="order-extra-service-caption">
                    Найти аудиторию за меня
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                    alt={"question"}
                    onClick={() => handleHint(baseHintContent)}
                  />
                </div>
                <div className="flipswitch">
                  <input
                    type="checkbox"
                    name="flipswitcha"
                    className="flipswitch-cb"
                    id="fsa"
                    checked={findAudience}
                    onChange={() => setFindAudience((prev) => !prev)}
                  />
                  <label className="flipswitch-label" htmlFor="fsa">
                    <div className="flipswitch-inner"></div>
                    <div className="flipswitch-switch"></div>
                  </label>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/4.svg`} alt="four" />
            <div className="order-hint-caption">
              Если вы хотите ещё что-нибудь добавить, то вы можете написать
              комментарий к заказу.
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-col">
              <div className="order-input-title">
                <img
                  src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                  alt={"question"}
                  onClick={() => handleHint(fourthHint)}
                  className="hide-on-desktop"
                />
                Оставьте комментарий
              </div>
              <input
                type="text"
                name="comment"
                className={`input-type-2`}
                placeholder="Ваше сообщение..."
              />
            </div>
          </Col>
        </Row>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/5.svg`} alt="five" />
            <div className="order-hint-caption">
              Создавайте заказ и наблюдайте за его статусом в личном кабинете :)
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-row order-create-order-row">
              <button
                className={`button-type-1 ${
                  loading ? "axios-loading" : undefined
                }`}
                type="submit"
                disabled={loading}
              >
                Сделать заказ
              </button>
              <div className="order-input-title">Итого: </div>
              <div className="order-extra-service-input-col">
                <div className="order-extra-service-caption">
                  {price}
                  {" руб."}
                  <img
                    src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                    alt={"question"}
                    onClick={() => handleHint(priceHintContent)}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default Inviting;
