import { gotOrders, addOrder } from "../orders";
import { gotUser, setFetchingStatus, withdrawUserBalance } from "../user";
import Cookies from "js-cookie";
import axios from "axios";
import Swal from "sweetalert2";

// USER THUNK CREATORS

const token = Cookies.get("token");
const _id = Cookies.get("_id");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  showCloseButton: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const fetchUser = () => async (dispatch) => {
  dispatch(setFetchingStatus(true));
  try {
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.headers.post["Authorization"] = `jwt ${token}`;
    axios.defaults.headers.get["Authorization"] = `jwt ${token}`;
    const { data } = await axios.post(
      `https://api.pavelandco.com/api/user/auth`,
      { _id: _id }
    );
    dispatch(gotUser(data));
    const response = await axios.get(
      `https://api.pavelandco.com/api/user/orders`,
      { _id: _id }
    );
    dispatch(gotOrders(response.data.reverse()));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(setFetchingStatus(false));
  }
};

export const signOn = (credentials) => async (dispatch) => {
  try {
    axios.defaults.headers.post["Content-Type"] = "application/json";
    const { data } = await axios.post(
      `https://api.pavelandco.com/api/user/${credentials.type}`,
      credentials
    );
    axios.defaults.headers.post["Authorization"] = `jwt ${data.token}`;
    axios.defaults.headers.get["Authorization"] = `jwt ${data.token}`;
    Cookies.set("token", data.token, { expires: 14 });
    Cookies.set("email", credentials.email, { expires: 14 });
    Cookies.set("_id", data.user._id, { expires: 14 });
    dispatch(gotUser(data.user));
    const response = await axios.get(
      `https://api.pavelandco.com/api/user/orders`,
      {
        _id: _id,
      }
    );
    await dispatch(gotOrders(response.data));
  } catch (error) {
    console.log(error);
    if (error.response.status === 400) {
      Toast.fire({
        icon: "error",
        title: "Неправильный пароль",
      });
    } else {
      Toast.fire({
        icon: "error",
        title: error.message,
      });
    }

    dispatch(gotUser({ error: error }));
    throw new Error(error);
  }
};

export const processOrder = (order) => async (dispatch) => {
  dispatch(addOrder(order));
  dispatch(withdrawUserBalance(order.priceRu));
};

export const logout = (id) => async (dispatch) => {
  try {
    await Cookies.remove("token");
    dispatch(gotUser({}));
  } catch (error) {
    console.error(error);
  }
};

export const singOnUnreg = (user, orders, token) => async (dispatch) => {
  Cookies.set("token", token, { expires: 14 });
  Cookies.set("email", user.email, { expires: 14 });
  Cookies.set("_id", user._id, { expires: 14 });
  dispatch(gotUser(user));
  await dispatch(gotOrders([orders]));
};
