import React, { useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { fetchUser } from "./Store/Utils/thunkCreators";
import Error from "./Components/Error/Error";
import Service from "./Components/Service/Service/Service";
import Cookies from "js-cookie";
import Orders from "./Components/Service/Orders/Orders";
import Telegram from "./Components/Service/Service/Telegram/Telegram";
import Balance from "./Components/Service/Balance/Balance";
import Settings from "./Components/Service/Settings/Settings";
import Message from "./Components/Service/Service/Telegram/Message/Message";
import Bots from "./Components/Service/Service/Telegram/Bot/Bots";
import Views from "./Components/Service/Service/Telegram/Views/Views";
import GroupMsg from "./Components/Service/Service/Telegram/GroupMsg/GroupMsg";
import Inviting from "./Components/Service/Service/Telegram/Inviting/Inviting";
import TikTok from "./Components/Service/Service/TikTok/TikTok";
import Likes from "./Components/Service/Service/TikTok/Likes";
import Shares from "./Components/Service/Service/TikTok/Shares";
import TTViews from "./Components/Service/Service/TikTok/TTViews";
import TTBots from "./Components/Service/Service/TikTok/TTBots";
import UnauthorizedOrder from "./Components/Service/Service/UnauthorizedOrder/UnauthorizedOrder";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Home/Home";
import Footer from "./Components/Footer/Footer";
import NewPass from "./Components/NewPass/NewPass";
import Faq from "./Components/Faq/Faq";
import Blog from "./Components/Blog/Blog";
import Post from "./Components/Blog/Post/Post";

const PageLayout = ({ children }) => children;

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  type: "tween",
  ease: "linear",
  duration: 0.5,
};

const AnimationLayout = () => {
  const { pathname } = useLocation();
  return (
    <PageLayout>
      <motion.div
        key={pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Outlet />
      </motion.div>
    </PageLayout>
  );
};

const AppRoutes = (props) => {
  const { user, fetchUser } = props;
  const [closeMobileNavbar, setCloseMobileNavbar] = useState(false);
  const [expandServicesMobileNav, setExpandServicesMobileNav] = useState(false);
  const token = Cookies.get("token");

  const [searchParams] = useSearchParams();
  let leadSource = searchParams.get("leadSource");

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    if (leadSource !== null) localStorage.setItem("leadSource", leadSource);
  }, [leadSource]);

  if (props.user && props.user.isFetchingUser) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar
        closeMobileNavbar={closeMobileNavbar}
        setCloseMobileNavbar={setCloseMobileNavbar}
        expandServicesMobileNav={expandServicesMobileNav}
        setExpandServicesMobileNav={setExpandServicesMobileNav}
      />
      <Routes>
        <Route path="/service/*" element={<Service />}>
          <Route element={<AnimationLayout />}>
            <Route
              path="orders"
              element={
                token ? (
                  <Orders />
                ) : (
                  <Navigate to="/service/telegram/message" replace={true} />
                )
              }
            />
            <Route
              path="balance"
              element={
                token ? (
                  <Balance />
                ) : (
                  <Navigate to="/service/telegram/message" replace={true} />
                )
              }
            />
            <Route
              path="settings"
              element={
                token ? (
                  <Settings />
                ) : (
                  <Navigate to="/service/telegram/message" replace={true} />
                )
              }
            />
            <Route
              path="pay"
              element={
                token ? (
                  <Navigate to="/service/orders" replace={true} />
                ) : (
                  <UnauthorizedOrder />
                )
              }
            />
            <Route path="telegram" element={<Telegram />}>
              <Route path="message" element={<Message />} />
              <Route path="bots" element={<Bots />} />
              <Route path="views" element={<Views />} />
              <Route path="group" element={<GroupMsg />} />
              <Route path="inviting" element={<Inviting />} />
            </Route>
            <Route path="tiktok" element={<TikTok />}>
              <Route path="likes" element={<Likes />} />
              <Route path="views" element={<TTViews />} />
              <Route path="share" element={<Shares />} />
              <Route path="subs" element={<TTBots />} />
            </Route>
            <Route path="*" element={<Error />} />
          </Route>
        </Route>
        <Route element={<AnimationLayout />}>
          <Route
            path="/newpass/:hash"
            element={token ? <Navigate to="/" replace /> : <NewPass />}
          />
          <Route path="/faq/:id" element={<Faq />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/blog/:link" element={<Post />} />
          <Route path="/blog" element={<Blog />} />
          <Route exact path="/" element={<Home leadSource={leadSource} />} />
          <Route path="*" element={<Error />} />
        </Route>
      </Routes>
      <Footer
        setCloseMobileNavbar={setCloseMobileNavbar}
        setExpandServicesMobileNav={setExpandServicesMobileNav}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser() {
      dispatch(fetchUser());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
