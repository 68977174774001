import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Order from "./Order/Order";
import "./Orders.css";

const Orders = () => {
  const user = useSelector((state) => state.user);
  const orders = useSelector((state) => state.orders);

  const calculateCompletedOrders =
    orders &&
    orders.reduce((total, order) => {
      if (order.status === "Completed" || order.status === "Declined") {
        return ++total;
      }

      return total;
    }, 0);

  const calculateActiveOrders = orders.length - calculateCompletedOrders;

  const ordersContent = (orders) => {
    if (orders.length === 0) {
      return (
        <>
          <Col className="orders-not-found">
            <div className="orders-not-found-title">Заказов не найдено...</div>
            <div className="orders-not-found-content">
              Как только Вы сделаете свой первый заказ, он появится здесь.
            </div>
          </Col>
        </>
      );
    } else {
      return (
        <>
          {orders.map((order) => (
            <Order order={order} key={order._id} unregistered={false} />
          ))}
        </>
      );
    }
  };

  return (
    <>
      <Container>
        <Row className="orders-balance-details-row">
          <Col md={4} xs={7} className="orders-balance-details-col">
            <img
              src={`${process.env.PUBLIC_URL}/images/wallet.svg`}
              alt="wallet"
            />
            <div className="orders-balance">
              <div className="orders-balance-title">Ваш баланс</div>
              <Link to="/service/balance">
                {user.balanceRu && user.balanceRu.toFixed(2)}₽
              </Link>
            </div>
          </Col>
          <Col md={4} xs={5} className="orders-top-up-button-col">
            <Link to="/service/balance">
              <button className="orders-top-up-button">ПОПОЛНИТЬ</button>
            </Link>
          </Col>
          <Col md={4} className="orders-counters-col">
            <div className="orders-counter">
              <div className="orders-counter-itself">
                {calculateActiveOrders}
              </div>
              <div className="orders-counter-title">Заказов в работе</div>
            </div>
            <div className="orders-counter">
              <div className="orders-counter-itself">
                {calculateCompletedOrders}
              </div>
              <div className="orders-counter-title">Выполнено заказов</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="your-orders-title">Ваши заказы</div>
          </Col>
        </Row>
        <Row className={orders.length === 0 && `orders-order-row`}>
          {ordersContent(orders)}
        </Row>
      </Container>
    </>
  );
};

export default Orders;
