import { Accordion, Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import "./Faq.css";
import { text } from "../../assets/texts/faq";
import { useState } from "react";

const Faq = () => {
  //   const { id } = useParams();

  return (
    <>
      <Container className="faq-page-container">
        <Row className="landing-faq-questions-container">
          <Col>
            {text.map((section, i) => {
              return (
                <Accordion key={`${i}`}>
                  <h2 className="faq-section-title">{section.title}</h2>
                  {section.content.map((content, j) => {
                    return (
                      <Accordion.Item
                        eventKey={`${i}-${j}`}
                        key={`${i}-${j}`}
                        id={`${i}-${j}`}
                      >
                        <Accordion.Header>{content.q}</Accordion.Header>
                        <Accordion.Body>{content.a}</Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              );
            })}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Faq;
