import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useState } from "react";
import "./Balance.css";
import { useSelector } from "react-redux";
import MD5 from "crypto-js/md5";
import axios from "axios";

const Balance = () => {
  const handleTopUp = () => {};
  const [amount, setAmount] = useState();
  const [promoId, setPromoId] = useState("");
  const [successAmount, setSuccessAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [promoResponse, setPromoResponse] = useState("");
  const user = useSelector((state) => state.user);

  const handlePromo = (e) => {
    const promo = e.target.value;
    const promoLength = promo.length;

    if (promoLength === 6) {
      fetchPromo(promo);
    }
    if (promoLength < 6 && promoResponse !== "") {
      setPromoResponse("");
    }
  };

  const sleep = (delay) => {
    return new Promise(function (resolve) {
      setTimeout(resolve, delay);
    });
  };

  const fetchPromo = async (promo) => {
    let message;
    try {
      setLoading(true);
      const res = await axios.get(
        `https://api.pavelandco.com/api/user/promo/?promo=${promo}&id=${user._id}`
      );
      setSuccessAmount(res.data.amount);
      setPromoId(res.data.id);
      message = `Вы успешно применили промокод на ${res.data.amount}%`;
      setPromoResponse(message);
    } catch (err) {
      switch (err.response.status) {
        case 400:
          message = "Возникла ошибка во время применения промокода";
          break;
        case 404:
          message = "Промокод не найден или истек";
          break;
        case 406:
          message = "Вы уже применяли данный промокод";
          break;
        default:
          message = "Возникла ошибка во время применения промокода";
      }
      setSuccessAmount(0);
      setPromoId(0);
      setPromoResponse(message);
    } finally {
      setLoading(false);
      await sleep(10000);
    }
  };

  return (
    <>
      <Container className="balance-container">
        <Row>
          <Col md={4}>
            <form
              className="balance-form"
              method="get"
              action="https://pay.freekassa.ru/"
              target="_blank"
            >
              <>
                <input type="hidden" name="m" defaultValue="36638" />
                <input type="hidden" name="o" defaultValue={user.nickname} />
                <input
                  type="hidden"
                  name="s"
                  value={MD5(
                    `36638:${amount}:]7}tV&VOK59vohP:RUB:${user.nickname}`
                  ).toString()}
                />
                <input type="hidden" name="currency" defaultValue="RUB" />
                <input type="hidden" name="us_promo" value={promoId} />
                <input type="hidden" name="lang" defaultValue="ru" />
                <input type="hidden" name="us_lang" defaultValue="ru" />
                <input
                  type="hidden"
                  name="us_amount"
                  value={amount * (1 + successAmount / 100) || 0}
                />
              </>
              <input type="hidden" name="us_id" defaultValue={user._id} />
              <div className="balance-input-field">
                <label htmlFor="amount">Укажите сумму в рублях:</label>
                <input
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                  type="number"
                  className="input-type-1"
                  name="oa"
                  id="amount"
                  placeholder="1000"
                  min="100"
                  required
                />
              </div>
              <div className="balance-input-field">
                <label htmlFor="promo">Введите промокод:</label>
                <input
                  className="input-type-1"
                  name="promo"
                  id="promo"
                  onChange={handlePromo}
                  placeholder="******"
                  maxLength="6"
                />
              </div>
              <div
                className={`balance-promo-field ${
                  successAmount === 0 && "error-promo"
                }`}
              >
                {promoResponse}
                {loading && <Spinner animation="border" variant="light" />}
              </div>
              <button type="submit" onClick={handleTopUp}>
                ОПЛАТИТЬ
              </button>
              <div className="hint-full">
                <div className="hint-icon">i</div>
                <span className="hint-text">
                  Баланс пополняется в течении 1 минуты
                </span>
              </div>
            </form>
          </Col>
          <Col md={8} className="balance-img-col hide-on-mobile">
            <img
              src={`${process.env.PUBLIC_URL}/images/coins.svg`}
              alt="coins"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Balance;
