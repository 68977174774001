import { Container, Row } from "react-bootstrap";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

const TikTok = () => {
  let title;
  const sampleLocation = useLocation();
  if (sampleLocation.pathname.includes("share")) {
    title = "Репосты";
  } else if (sampleLocation.pathname.includes("likes")) {
    title = "Лайки";
  } else if (sampleLocation.pathname.includes("subs")) {
    title = "Подписчики";
  } else if (sampleLocation.pathname.includes("views")) {
    title = "Просмотры";
  }
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="likes" replace={true} />} />
      </Routes>
      <Container>
        <Row className="you-chose-services-row">
          <div className="you-chose-services">Вы выбрали {title}</div>
        </Row>
        <Outlet />
      </Container>
    </>
  );
};

export default TikTok;
