import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import { blogs } from "../../../assets/blogs/blogs";
import VisibilitySensor from "react-visibility-sensor";
import "./Post.css";

const Post = () => {
  const { link } = useParams();
  const [activeSection, setActiveSection] = useState(0);

  const { img, title, caption, footer, sections, anotation } = blogs.find(
    (blog) => blog.link === link
  );

  const handelMenuClick = (index) => {
    scroller.scrollTo(index, {
      duration: 100,
    });
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  //   const inViewport = useIntersection(elementsRef.current[0]);

  //   if (inViewport) {
  //     console.log("in viewport:", elementsRef.current);
  //   }

  return (
    <>
      <Container className="post-page-container">
        <Row>
          <Col className="post-page-title-and-anotation-col">
            <div className="post-page-title">{title}</div>
            <div className="post-page-anotation">{anotation}</div>
          </Col>
        </Row>
        <Row>
          <Col className="post-page-title-image-col">
            <img
              src={`${process.env.PUBLIC_URL}/images/${img}.svg`}
              alt={img}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div className="sticky-post-contetn-menu">
              <div
                className={`sticky-post-contetn-menu-title ${
                  activeSection === 0
                    ? "active-sticky-post-contetn-menu-title"
                    : undefined
                }`}
                onClick={() => {
                  handelMenuClick(0);
                }}
              >
                Введение
              </div>
              {sections.map((section, index) => {
                return (
                  <div
                    className={`sticky-post-contetn-menu-title ${
                      activeSection === index + 1
                        ? "active-sticky-post-contetn-menu-title"
                        : undefined
                    }`}
                    onClick={() => {
                      handelMenuClick(index + 1);
                    }}
                  >
                    {section.title}
                  </div>
                );
              })}
              <div
                className={`sticky-post-contetn-menu-title ${
                  activeSection === 999
                    ? "active-sticky-post-contetn-menu-title"
                    : undefined
                }`}
                onClick={() => {
                  handelMenuClick(999);
                }}
              >
                Заключение
              </div>
            </div>
          </Col>
          <Col md={8}>
            <div className="post-text-container">
              <Element name={`0`} />
              <VisibilitySensor
                onChange={(e) => {
                  if (e) {
                    setActiveSection(0);
                  }
                }}
              >
                <div className="post-text single-post-section">{caption}</div>
              </VisibilitySensor>
              {sections.map((section, index) => {
                return (
                  <div className="single-post-section" key={section.title}>
                    <Element name={index + 1} />
                    <VisibilitySensor
                      onChange={(e) => {
                        if (e) {
                          setActiveSection(index);
                        }
                      }}
                    >
                      <div className="post-text-title">{section.title}</div>
                    </VisibilitySensor>
                    <div className="post-text">{section.text}</div>
                    <ul className="post-list">
                      {section.list?.map((item) => {
                        return (
                          <li key={item.text}>
                            {item.title !== "" && (
                              <span className="post-list-title">
                                {item.title}
                              </span>
                            )}
                            <span className="post-list-text">{item.text}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
              <Element name={`999`} />
              <VisibilitySensor
                onChange={(e) => {
                  if (e) {
                    setActiveSection(999);
                  }
                }}
              >
                <div className="post-text single-post-section">{footer}</div>
              </VisibilitySensor>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Post;
