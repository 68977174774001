export const text = [
  {
    title: "Парсинг",
    tag: "parsing",
    content: [
      {
        q: "Парсинг: что это?",
        a: "Парсинг - это процесс сбора участников из чата. Из всей совокупности аудитории группового чата выбираются только те люди, которые подходят под заданные параметры.  недавно были в сети.",
      },
      {
        q: "Можно ли отсеять нежелательных участников группы?",
        a: "Да, можно. При парсинге все администраторы, модераторы, боты, мертвые и неактивные участники автоматически убираются из списка, если в комментарии к заказу не указано обратно. Удалить отдельных активных участников чата также возможно - достаточно прикрепить их номера или юзернеймы в комментарии к заказу.",
      },
      {
        q: "По каким параметрам парсятся участники из групп?",
        a: "Участники фильтруются по активности и вовлеченности. Парсить можно тех, кто был онлайн час назад, 5 часов назад, за последние сутки/неделю/месяц (сроки указаны в качестве примера). НЕЛЬЗЯ парсить участников по: полу, возрасту, локации, количеству отправленных в чат сообщений.",
      },
      {
        q: "У меня нет чатов для парсинга, что делать?",
        a: "Команда профессионалов нашего сервиса подбирает чаты за дополнительную плату. Все работы ведутся по согласованию с клиентом с учетом индивидуальных особенностей заказа.",
      },
      {
        q: "Можно ли спарсить пользователей из каналов?",
        a: "К сожалению, парсинг из каналов невозможен. Спарсить аудиторию можно только из группового чата Telegram вашей тематики.",
      },
      {
        q: "Не могу найти ссылку на нужный чат, что делать?",
        a: "Как правило, ссылки указаны в описании чата или могут быть закреплены в самом чате. Ссылку также можно найти в описании канала, если групповой чат привязан к нему. К сожалению, чаты без ссылки или юзернейма невозможно спарсить.",
      },
      {
        q: "Что делать, если чат закрытый?",
        a: "В таком случае спарсить чат получится, только если у вас есть возможность добавить в этот чат нашего бота. К сожалению, другой возможности спарсить закрытый чат нет.",
      },
    ],
  },
  {
    title: "Рассылка в Telegram",
    tag: "tg_spam",
    content: [
      {
        q: "Что такое рассылка?",
        a: "Рассылка - это услуга, с помощью которой можно прорекламировать свой канал, чат или бота в Телеграме, а также любой внешний ресурс (сайт, товар, услуга). В личные сообщения вашей целевой аудитории приходит текст со ссылкой на ваш ресурс.",
      },
      {
        q: "Как осуществляется рассылка?",
        a: "Путем парсинга собираются активные участники из чатов вашей тематики, после чего каждому из них рассылаются сообщения. Обращаем внимание, что все ссылки скрываются через бота или редирект во избежании бана вашего ресурса или ссылки на ресурс.",
      },
      {
        q: "Как ссылка “прячется” через редирект?",
        a: "Пользователю приходит ваше сообщение, но вместо ссылки на ваш ресурс указана ссылка на редирект. Когда человек переходит по ней - его перенаправляют по вашей ссылке. Это исключает возможность бана вашего ресурса или ссылки на ресурс.",
      },
      {
        q: "Как ссылка “прячется” через бота?",
        a: "В сообщении пользователя вместо ссылки на ваш ресурс указана ссылка на бота, созданного нашим сервисом. Человек переходит по ссылке и активирует бота, который высылает пользователю ссылку на ваш ресурс. Это защищает ваш ресурс и ссылку на него от нежелательного бана.",
      },
      {
        q: "Не могу/не хочу составлять сообщение самостоятельно, можете помочь?",
        a: "Да, мы составляем рекламные сообщения за дополнительную плату. Перед рассылкой сообщение отправляется на согласование клиенту.",
      },
      {
        q: "Есть ли рекомендации по тексту для рассылки",
        a: "Составили рекомендации по составлению текста для рассылки: небольшой объем, разбитый на симметричные абзацы, можно добавить эмоджи. При желании можно добавить один медиа-файл.",
      },
      {
        q: "Как сформировать портрет целевой аудитории для рассылки?",
        a: "Аудитория формируется из чатов вашей тематики/конкурентов. Также можно сделать рассылку по вашей базе номеров. Более подробный ответ на этот вопрос можно найти в разделе Парсинг.",
      },
      {
        q: "Хочу сделать рассылку по моей базе номеров, это возможно?",
        a: "Да, возможно, если указанные номера зарегистрированы в сети Telegram. Мы сами это проверим совершенно бесплатно.",
      },
      {
        q: "С какой скоростью происходит рассылка?",
        a: "Рассылка осуществляется оперативно. Менее, чем за один час можно разослать 500 сообщений. За сутки рассылается примерно 15 000 сообщений.",
      },
      {
        q: "Как узнать: были ли разосланы сообщения и кому именно?",
        a: "По окончании рассылки мы предоставляем подробный отчет в формате ссылки. В облачном хранилище сформирован список пользователей, получивших сообщение, а также логи и скриншоты рабочего процесса.",
      },
    ],
  },
  {
    title: "Цикличная рассылка по Телеграм-чатам",
    tag: "tg_groupSpam",
    content: [
      {
        q: "Что такое цикличная рассылка по чатам?",
        a: "По чатам вашей тематики рассылается сообщение со ссылкой на ваш канал, чат или бот, а также любой внешний ресурс (сайт, товар, услуга). В групповые чаты сообщение приходит сразу и его видят все участники.",
      },
      {
        q: "В чем разница между цикличной рассылкой и рассылкой по личным сообщениям?",
        a: "Рассылка по личным сообщениям приходит персонально каждому активному участнику чата. Преимущество такой рассылки в том, что человек с 99% вероятностью увидит ваше сообщение и перейдет по указанной ссылке. В цикличной рассылке сообщение рассылается в чаты и его видят сразу все участники, а не только активные. В таком случае сообщение может быть быстро удалено админами и модераторами. Также в активных чатах сообщения быстро уходят вверх в переписке и могут остаться незамеченными даже активными пользователями.",
      },
      {
        q: "Как осуществляется цикличная рассылка?",
        a: "В чаты вашей тематики приходит ваше сообщение со ссылкой на ваш ресурс. Все ссылки скрываются через бота или редирект во избежании бана вашего ресурса или ссылки на ресурс.",
      },
      {
        q: "Как ссылка “прячется” через редирект?",
        a: "Пользователю приходит ваше сообщение, но вместо ссылки на ваш ресурс указана ссылка на редирект. Когда человек переходит по ней - его перенаправляют по вашей ссылке. Это исключает возможность бана вашего ресурса или ссылки на ресурс.",
      },
      {
        q: "Как ссылка “прячется” через бота?",
        a: "В сообщении пользователя вместо ссылки на ваш ресурс указана ссылка на бота, созданного нашим сервисом. Человек переходит по ссылке и активирует бота, который высылает пользователю ссылку на ваш ресурс. Это защищает ваш ресурс и ссылку на него от нежелательного бана.",
      },
      {
        q: "Не могу/не хочу составлять сообщение самостоятельно, можете помочь?",
        a: "Наш сервис за дополнительную плату составляет рекламные сообщения. Перед рассылкой текст отправляется вам на согласование. Рекомендации по составлению текста для рассылки: небольшой объем, разбитый на симметричные абзацы, можно добавить эмоджи. При желании можно добавить один медиа-файл.",
      },
      {
        q: "Как найти чаты для рассылки?",
        a: "Поиск в Telegram осуществляется по ключевым словам. Если вы не знаете, как это сделать - мы можем сделать это за вас. Услуга предоставляется за дополнительную плату.",
      },
      {
        q: "Как часто можно отправлять сообщение в чаты?",
        a: "Минимальный интервал отправки сообщения в один чат - 1 час. Можно отправлять сообщение в чат и реже, например один раз в 3 часа.",
      },
    ],
  },
  {
    title: "Инвайтинг",
    tag: "tg_inviting",
    content: [
      {
        q: "Инвайтинг: что это?",
        a: "Инвайтинг - это процесс добавления вашей целевой аудитории в ваш Телеграм-чат. После того, как пользователя заинвайтили, он сразу обнаруживает себя участником чата, что делает инвайтинг невероятно эффективным способом продвижения групповых чатов.",
      },
      {
        q: "Как происходит инвайтинг?",
        a: "В групповой чат добавляются наши аккаунты, которые в свою очередь инвайтят (добавляют) собранных из других чатов пользователей.",
      },
      {
        q: "С какой скоростью можно добавлять людей?",
        a: "Инвайтинг происходит постепенно. В сутки можно добавлять неограниченное кол-во пользователей, но безопасной считается отметка в 200 приглашений . С постепенным ростом чата увеличивается количество инвайтов в день. В небольшие чаты возможно добавлять до 100 человек в сутки. Приведенные практики показали себя максимально эффективными и безопасными в работе с Инвайтингом.",
      },
      {
        q: "Как подобрать мою целевую аудиторию для инвайтинга?",
        a: "Портрет целевой аудитории формируется на основе чатов вашей тематики/конкурентов. Также можно сделать инвайтинг по базе телефонных номеров. Более подробный ответ на этот вопрос можно найти в разделе Парсинг.",
      },
      {
        q: "Хочу заинвайтить людей из своей базы номеров, это возможно?",
        a: "Да, возможно, если указанные номера зарегистрированы в сети Telegram. Мы сами это проверим совершенно бесплатно (на большие базы более 50 000 номеров будет взыматься плата, за подробностями обращайтесь в поддержку)!",
      },
    ],
  },
  {
    title: "Накрутка ботов в Телеграм",
    tag: "tg_bots",
    content: [
      {
        q: "Что такое накрутка ботов?",
        a: "Боты - это неживые аккаунты, которые выглядят, как аккаунты настоящих людей. Накрутка ботов - это процесс добавления ботов в группы и каналы в Телеграме с целью увеличения количества подписчиков. Данный инструмент продвижения вызывает большее доверие к вашему ресурсу и делает его популярнее.",
      },
      {
        q: "Как происходит накрутка?",
        a: "Вы прикрепляете ссылку на ваш канал/чат/бот, указываете пожелания по скорости накрутки, после чего количество подписчиков увеличивается. Результат можно увидеть почти сразу по увеличивающемуся количеству участников в вашей ресурсе.",
      },
      {
        q: "Как выглядят боты?",
        a: "Это русские аккаунты пользователей с именами и аватарками (фотографиями).",
      },
      {
        q: "Боты показываются, как “онлайн”, в чатах?",
        a: "Частично. Некоторые из них активны и находятся “в сети”, а некоторые - нет. Это нужно, чтобы чат выглядел более живым.",
      },
      {
        q: "Боты могут что-либо писать в чат?",
        a: "Нет, боты неактивны и не оставляют сообщения в чате.",
      },
    ],
  },
  {
    title: "Просмотры в Телеграм",
    tag: "tg_views",
    content: [
      {
        q: "Что такое просмотры?",
        a: "На постах в Telegram-каналах есть возможность отслеживать количество просмотров. Они показывают, сколько человек увидели данный конкретный пост.",
      },
      {
        q: "Как происходит накрутка?",
        a: "Вы прикрепляете ссылку на ваш канал и указываете подходящий для вас тариф, а также пожелания по скорости накрутки. Далее специалисты нашего сервиса накручивают просмотры на ваши посты. Результат можно увидеть почти сразу на ресурсе.",
      },
      {
        q: "В чем суть тарифов?",
        a: "Оплата по тарифу осуществляется раз в месяц. Далее в течение 30 дней на каждый ваш пост идет накрутка. Количество постов в месяц не ограничено, то есть просмотры накрутятся на абсолютно каждый новый пост.",
      },
      {
        q: "Можно ли накручивать не равное количество просмотров на пост, чтобы канал выглядел живее?",
        a: "Да, можно. Для того чтобы просмотры выглядели живыми, мы накручиваем их с разбросом ±10%.",
      },
    ],
  },
];
