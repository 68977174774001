import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import "./Blog.css";
import { blogs } from "../../assets/blogs/blogs";

const Blog = () => {
  const handleScrollToContent = () => {
    scroller.scrollTo("blog-page-content", {
      duration: 100,
    });
  };

  return (
    <>
      <Container className="blog-page-title-container">
        <Row>
          <Col className="blog-page-title-col">
            <div className="blog-page-title">
              Хотите узнать больше о продвижении в соц сетях?
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="blog-page-title-caption-col">
            <div className="blog-page-title-caption">
              В нашем блоге вы сможете найти интересные статьи о интересных
              деталях, специфике и новых тенденциях в мире продвижения.
              <br />
              Приятного чтения!
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="blog-page-title-img-col">
            <img src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="logo" />
          </Col>
        </Row>
        <Row>
          <Col className="blog-page-title-arrow-down-col">
            <button
              onClick={() => handleScrollToContent()}
              className="navbar-link blog-arrrow-down"
            >
              К статям &#8595;
            </button>
          </Col>
        </Row>
      </Container>
      <Container className="blog-page-content-container">
        <Element name="blog-page-content"></Element>
        <Row>
          {blogs.map((blog) => {
            return (
              <>
                <Col xs={12} md={4} className="blog-preview-col">
                  <Link to={`${blog.link}`} className="no-link-decoration">
                    <div
                      className="benefits-card blog-preview-card"
                      style={{ "--bg": `${blog.backgroundColor}` }}
                    >
                      <div className="blog-post-preview-title">
                        <div className="blog-post-preview-title-itself">
                          {blog.title}
                        </div>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/${blog.img}.svg`}
                          alt={blog.img}
                        />
                      </div>
                      <div className="blog-post-preview-text">
                        {blog.caption}
                      </div>
                    </div>
                  </Link>
                </Col>
              </>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

export default Blog;
