export const reviews = [
  {
    id: 1,
    name: "Wo1fwram",
    service: "Просмотры",
    link: "/service/telegram/views",
    color: "#CF88B3",
    text: "Работаем уже очень давно . Заказывал разные услуги по телеграмму остался доволен, проблем не возникало . Советую",
  },
  {
    id: 2,
    name: "Someonehere",
    service: "Рассылка в лc",
    link: "/service/telegram/message",
    color: "#FFBD3C",
    text: "Заказывали рассылки не раз, эффективность выше чем у других. Результатом доволен, заказы делаем на регулярной основе.",
  },
  {
    id: 3,
    name: "Sovynia",
    service: "Накрутка ботов",
    link: "/service/telegram/bots",
    color: "#4BC0C8",
    text: "Доброго времени суток! Работали, все ок! Спасибо!",
  },
  {
    id: 4,
    name: "OneTerest",
    service: "Просмотры",
    link: "/service/telegram/views",
    color: "#C779D0",
    text: "Работаем давно, все делают качественно, одна из лучших настроек по просмотрам телеграм на рынке.",
  },
  {
    id: 5,
    name: "izlodey",
    service: "Инвайтинг",
    link: "/service/telegram/inviting",
    color: "#827BE6",
    text: "Работали неделю по продвижению группы в Телеграм. Все сделано четко и в срок. Помогал все сделать правильно, всегда на связи. Рекомендую. Думаю продолжу сотрудничество по другим проектам.",
  },
  {
    id: 6,
    name: "agrr",
    service: "Инвайтинг",
    link: "/service/telegram/inviting",
    color: "#CF88B3",
    text: "Сотрудничаю с P&C с апреля 2020. Наполняю два чата и уже подключаю в работу третий. Проблем не было. Чаты растут, оживают и начинают жить собственной полноценной жизнью. Рекомендую!",
  },
  {
    id: 7,
    name: "perforat0r",
    service: "Рассылка в лс",
    link: "/service/telegram/message",
    color: "#CF88B3",
    text: "Была заказана рассылка по ЛС. Сделка прошла успешно, порадовал подробный отчёт. Можно работать, спасибо.",
  },
  {
    id: 8,
    name: "erber",
    service: "Рассылка в лс",
    link: "/service/telegram/message",
    color: "#7B99E6",
    text: "Сработались с ТС по инвайту и рассылке по лс по моему проекту в блокчейне. Адекватность и отзывчивость на уровне, результатом работы доволен.",
  },
];
