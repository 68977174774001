import { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Swal from "sweetalert2";
import { calculatePriceSpam } from "../../CalculatePrice";

import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { processOrder } from "../../../../../Store/Utils/thunkCreators";

const GroupMsg = () => {
  const token = Cookies.get("token");

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [days, setDays] = useState(42);
  const [times, setTimes] = useState(12);
  const [groupAmount, setGroupAmount] = useState(1);
  const [text, setText] = useState("");
  const [groups, setGroups] = useState("");
  const [messageFile, setMessageFile] = useState([]);
  const messageFileSelected = Object.values(messageFile).length > 0;
  const [mediaFile, setMediaFile] = useState([]);
  const mediaFileSelected = Object.values(mediaFile).length > 0;

  const [makeMessage, setMakeMessage] = useState(false);
  const [findAudience, setFindAudience] = useState(false);

  let formData = { tg_groupSpam: {} };

  const amount = days * times * groupAmount;

  useEffect(() => {
    setGroupAmount(groups.replace(/  +/g, " ").trim().split(" ").length);
  }, [groups]);

  const price = calculatePriceSpam(
    "tg_groupSpam",
    amount,
    makeMessage,
    findAudience
  );

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    showCloseButton: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const textHintContent = {
    title:
      "Не могу/не хочу составлять сообщение самостоятельно, можете помочь?",
    text: "Да, можем составить сообщение за вас за дополнительную плату. Перед рассылкой сообщение отправляется вам на согласование.",
  };
  const baseHintContent = {
    title: "У меня нет чатов для парсинга, что делать?",
    text: "Если у вас нет чатов или не знаете, как их искать, чаты сможем подобрать мы за дополнительную плату. Перед выполнением услуги чаты будут отправляться вам на согласование.",
  };

  const priceHintContent = {
    title: "Цена",
    html: ` <div class="container-fluid pricing-hint-container">
        <div class="row">
          <div class="col-11 ${
            amount < 5001 && "price-gap-selected"
          }">500-5000 Сообщения- 6 руб. за сообщение </div>
          <div class="col-1">${
            amount < 5001
              ? ` <svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <div class="row">
          <div class="col-11 ${
            amount >= 5001 && amount < 10001 && "price-gap-selected"
          }">5001-10000 Сообщения - 5 руб. за сообщение</div>
          <div class="col">${
            amount >= 5001 && amount < 10001
              ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <div class="row">
          <div class="col-11 ${
            amount > 10000 && "price-gap-selected"
          }">10000+ Сообщения - 4 руб. за сообщение</div>
          <div class="col">${
            amount > 10000
              ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
              : ""
          }</div>
        </div>
        <hr />
        <div class="row">
        <div class="col-11 ${
          makeMessage && "price-gap-selected"
        }">Написать за меня текст: +0.5 руб. за сообщение</div>
        <div class="col">${
          makeMessage
            ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
            : ""
        }</div>
      </div>
      <div class="row">
      <div class="col-11 ${
        findAudience && "price-gap-selected"
      }">Найти Аудиторию за меня: +0.5 руб. за сообщение</div>
      <div class="col">${
        findAudience
          ? `<svg
          width="17"
          height="17"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="18.5" r="18" stroke="#6C72F1" />
          <path d="M8 18.3636L15.7647 26L30 12" stroke="#6C72F1" stroke-width="2" />
        </svg>`
          : ""
      }</div>
    </div>
      </div>`,
  };

  const handleHint = (content) => {
    Swal.fire({
      title: content.title,
      text: content.text,
      html: content.html,
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        popup: "pop-up-type-1 hint-pop-up",
        title: "pop-up-type-1-title",
        closeButton: "pop-up-type-1-close",
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (amount < 500) {
      Toast.fire({
        icon: "error",
        title: `Мин заказ 500 сообщений (сейчас - ${amount}), увеличьте кол-во дней/раз в день/групп`,
      });
      return;
    }

    formData.amount = amount;
    formData.makeMessage = makeMessage;
    formData.findAudience = findAudience;
    formData.comment = e.target.comment.value;
    formData.type = "tg_groupSpam";
    formData.status = "Queued up";
    formData.dateOrdered = new Date();
    formData.priceRu = parseFloat(price);
    formData.priceEn = 0;
    formData.tg_groupSpam.days = days;
    formData.tg_groupSpam.timesPerDay = times;

    const formFile = new FormData();

    if (!makeMessage && messageFileSelected) {
      for (let file of messageFile) {
        formFile.append("textFiles", file);
      }
    } else if (!makeMessage && !messageFileSelected) {
      formData.tg_groupSpam.textFiles = [text];
    }

    if (!findAudience) {
      formData.tg_groupSpam.groupList = groups
        .replace(/  +/g, " ")
        .trim()
        .split(" ");
    }

    if (mediaFile) {
      for (let file of mediaFile) {
        formFile.append("mediaFiles", file);
      }
    }
    setLoading(true);
    formFile.append("fields", JSON.stringify(formData));
    axios.defaults.headers.post["Authorization"] = `jwt ${token}`;
    if (!token) {
      setLoading(false);
      return navigate("/service/pay", {
        state: {
          formData,
        },
      });
    }
    try {
      const { data } = await axios.post(
        `https://api.pavelandco.com/api/user/order/tggroupmessage/ru`,
        formFile
      );
      Toast.fire({
        icon: "success",
        title: "Заказ успешно создан",
      });
      dispatch(processOrder(data));
      return navigate("/service/orders");
    } catch (error) {
      if (error.request.status === 406) {
        Toast.fire({
          icon: "error",
          title: `Недостаточно средств на балансе  (${
            price - user.balanceRu
          } р)`,
        });
      } else if (error.request.status === 401) {
        Toast.fire({
          icon: "error",
          title: `Отсутствует регистрация`,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: `Ошибка - ${error}`,
        });
      }
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/1.svg`} alt="one" />
            <div className="order-hint-caption">
              Выберите количество дней и сообщений в день
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-col">
              <div className="order-input-title">Количество</div>
              <div className="order-input-title-hint hide-on-mobile">Дней</div>
              <div className="order-input-range-field double-range-input mb-3">
                <input
                  type="range"
                  max={365}
                  value={days}
                  onChange={(e) => setDays(e.target.value)}
                  required
                  step={1}
                />
                <div className="order-double-title-input">
                  <div className="order-input-title-hint hide-on-desktop">
                    Дней
                  </div>
                  <div className="range-input-numer-field">
                    <input
                      type="number"
                      min={1}
                      max={365}
                      value={days}
                      required
                      onChange={(e) => setDays(e.target.value)}
                    />
                    <div className="order-range-input-caption">Дней</div>
                  </div>
                </div>
              </div>
              <div className="order-input-title-hint hide-on-mobile">
                Сообщений в день
              </div>
              <div className="order-input-range-field double-range-input">
                <input
                  type="range"
                  min={1}
                  max={24}
                  value={times}
                  onChange={(e) => setTimes(e.target.value)}
                  required
                  step={1}
                />
                <div className="order-double-title-input">
                  <div className="order-input-title-hint hide-on-desktop">
                    Сообщений в день
                  </div>
                  <div className="range-input-numer-field">
                    <input
                      type="number"
                      min={1}
                      max={24}
                      value={times}
                      required
                      onChange={(e) => setTimes(e.target.value)}
                    />
                    <div className="order-range-input-caption">Сообщений</div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/2.svg`} alt="two" />
            <div className="order-hint-caption">
              Введите текст сообщения. Длина сообщения - до 1000 символов,
              включая эмодзи. Добавьте документ, если текст уже есть или мы
              можем написать текст за вас.
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-col">
              <div className="order-input-title">Текст сообщения</div>
              <div className="order-input-title-hint hide-on-mobile">
                Мин. 1 Уникальный текст на каждые 5000 сообщений
              </div>
            </div>
            <div
              contentEditable={!makeMessage && !messageFileSelected}
              suppressContentEditableWarning={true}
              className={`input-type-2 ${makeMessage && "disabled-input"} ${
                messageFileSelected && "file-selected-input"
              }`}
              placeholder="Ваш текст рассылки"
              value={text}
              onInput={(e) => setText(e.currentTarget.innerText)}
            >
              {makeMessage && !messageFileSelected && "Ваш текст рассылки"}
              {messageFileSelected && (
                <>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/lock.svg`}
                    alt="lock"
                  />{" "}
                  Уже добавлен файл
                </>
              )}
            </div>
            <div className="order-file-input-row">
              <OverlayTrigger
                trigger={["click", "hover"]}
                placement="bottom"
                delay={{ show: 150, hide: 150 }}
                overlay={
                  token ? (
                    <></>
                  ) : (
                    <Tooltip id="button-tooltip-2">
                      Загрузка файлов доступна зарегистрированным пользователям
                    </Tooltip>
                  )
                }
              >
                <div
                  className={`order-file-input-col ${
                    !token || makeMessage ? "disabled-file" : undefined
                  }`}
                >
                  <div
                    className={`order-file-input ${
                      messageFileSelected && "file-selected-input-selected"
                    }`}
                  >
                    <label
                      htmlFor="file-input-field"
                      className={
                        messageFileSelected ? "file-selected" : undefined
                      }
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/images/${
                          messageFileSelected ? "cross" : "plus"
                        }.svg`}
                        alt="plus"
                      />
                      {messageFileSelected
                        ? "Очистить файлы"
                        : "Загрузить документ"}
                    </label>
                    <input
                      required={!makeMessage && text.trim() === ""}
                      disabled={!token || makeMessage}
                      type="file"
                      id="file-input-field"
                      title=""
                      onChange={(event) => {
                        setMessageFile(event.target.files);
                      }}
                      accept=".txt,.docx,.doc,.pdf,.pages"
                      onClick={(e) => {
                        if (messageFileSelected) {
                          e.preventDefault();
                          e.target.value = null;
                          setMessageFile({});
                        }
                      }}
                    />
                  </div>
                  <div className="file-input-field-selected-file">
                    {Object.values(messageFile).map((file) => file.name)}
                  </div>
                </div>
              </OverlayTrigger>
              <div className="order-extra-service-input-col">
                <div className="order-extra-service-without-switch">
                  <div className="order-extra-service-caption">
                    Написать за меня текст
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                    alt={"question"}
                    onClick={() => handleHint(textHintContent)}
                  />
                </div>
                <div className="flipswitch">
                  <input
                    type="checkbox"
                    name="flipswitch"
                    className="flipswitch-cb"
                    id="fs"
                    checked={makeMessage}
                    onChange={() => setMakeMessage((prev) => !prev)}
                  />
                  <label className="flipswitch-label" htmlFor="fs">
                    <div className="flipswitch-inner"></div>
                    <div className="flipswitch-switch"></div>
                  </label>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/3.svg`} alt="three" />
            <div className="order-hint-caption">
              Добавьте картинку, это опциональный шаг и его можно пропустить. На
              каждые 5000 сообщений необходима как минимум одна уникальная
              картинка
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-col">
              <div className="order-input-title">Изображение</div>
            </div>
            <div className="order-file-input-row">
              <OverlayTrigger
                trigger={["click", "hover"]}
                placement="bottom"
                delay={{ show: 150, hide: 150 }}
                overlay={
                  token ? (
                    <></>
                  ) : (
                    <Tooltip id="button-tooltip-2">
                      Загрузка файлов доступна зарегистрированным пользователям
                    </Tooltip>
                  )
                }
              >
                <div
                  className={`order-file-input-col ${
                    !token || makeMessage ? "disabled-file" : undefined
                  }`}
                >
                  <div
                    className={`order-file-input ${
                      mediaFileSelected
                        ? "file-selected-input-selected"
                        : undefined
                    }`}
                  >
                    <label
                      htmlFor="file-input-field"
                      className={
                        mediaFileSelected ? "file-selected" : undefined
                      }
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/images/${
                          mediaFileSelected ? "cross" : "plus"
                        }.svg`}
                        alt="plus"
                      />
                      {mediaFileSelected
                        ? "Очистить файлы"
                        : "Загрузить Изображение"}
                    </label>
                    <input
                      disabled={!token || makeMessage}
                      type="file"
                      id="file-input-field"
                      title=""
                      name="image"
                      multiple
                      accept="image/*"
                      onChange={(event) => setMediaFile(event.target.files)}
                      onClick={(e) => {
                        if (mediaFileSelected) {
                          e.preventDefault();
                          e.target.value = null;
                          setMediaFile({});
                        }
                      }}
                    />
                  </div>
                  <div className="file-input-field-selected-file">
                    {Object.values(mediaFile).map((file) => `${file.name} `)}
                  </div>
                </div>
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/4.svg`} alt="four" />
            <div className="order-hint-caption">
              Введите список групповых чатов, по которым будем проводиться
              рассылка,
              <b> разделяя чаты пробелом</b> между собой.
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-col">
              <div className="order-input-title">
                Групповые чаты для рассылки: {groupAmount}
              </div>
              <input
                required={!findAudience}
                type="text"
                name="groups"
                value={groups}
                onChange={(e) => {
                  setGroups(e.target.value);
                }}
                className={`input-type-2 ${findAudience && "disabled-input"} 
                 `}
                placeholder={"Ссылка на групповой чат"}
                disabled={findAudience}
              />
              <div className="order-extra-service-input-col">
                <div className="order-extra-service-without-switch">
                  <div className="order-extra-service-caption">
                    Найти аудиторию за меня
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                    alt={"question"}
                    onClick={() => handleHint(baseHintContent)}
                  />
                </div>
                <div className="flipswitch">
                  <input
                    type="checkbox"
                    name="flipswitcha"
                    className="flipswitch-cb"
                    id="fsa"
                    checked={findAudience}
                    onChange={() => setFindAudience((prev) => !prev)}
                  />
                  <label className="flipswitch-label" htmlFor="fsa">
                    <div className="flipswitch-inner"></div>
                    <div className="flipswitch-switch"></div>
                  </label>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/5.svg`} alt="five" />
            <div className="order-hint-caption">
              Если вы хотите ещё что-нибудь добавить, то вы можете написать
              комментарий к заказу.
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-col">
              <div className="order-input-title">Оставьте комментарий</div>
              <input
                type="text"
                name="comment"
                className={`input-type-2`}
                placeholder="Ваше сообщение..."
              />
            </div>
          </Col>
        </Row>
        <Row className="order-form-row">
          <Col md={4} className="order-hint-col">
            <img src={`${process.env.PUBLIC_URL}/images/6.svg`} alt="six" />
            <div className="order-hint-caption">
              Создавайте заказ и наблюдайте за его статусом в личном кабинете :)
            </div>
          </Col>
          <Col md={1} className="hide-on-mobile" />
          <Col md={7} xs={12}>
            <div className="order-input-row order-create-order-row">
              <button
                className={`button-type-1 ${
                  loading ? "axios-loading" : undefined
                }`}
                type="submit"
                disabled={loading}
              >
                Сделать заказ
              </button>
              <div className="order-input-title">Итого: </div>
              <div className="order-extra-service-input-col">
                <div className="order-extra-service-caption">
                  {isNaN(price) ? "Ошибка" : price}
                  {" руб."}
                  <img
                    src={`${process.env.PUBLIC_URL}/images/questionMark.svg`}
                    alt={"question"}
                    onClick={() => handleHint(priceHintContent)}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default GroupMsg;
